import { Button, Input, InputRef, Space, Typography } from 'antd';
import { CargoCategory } from '../../../../../../Models/CargoCategories';
import { ColumnType, FilterConfirmProps, FilterValue } from 'antd/lib/table/interface';
import { ColumnsType } from 'antd/lib/table';
import { Dispatch, SetStateAction, useRef, useState } from 'react';
import { FilterOutlined } from '@ant-design/icons';

export const getCargoCategoriesTableColumns = (
  filteredInfo: Record<string, FilterValue | null>,
  setFilteredInfo: Dispatch<SetStateAction<Record<string, FilterValue | null>>>,
  handleOpenDeleteModal: (id: number) => void,
) => {
  const columns: ColumnsType<CargoCategory> = [
    {
      title: 'Категория техники',
      dataIndex: 'parentTitle',
      sorter: (a, b) => a.parentTitle.localeCompare(b.parentTitle),
      key: 'parentTitle',
      width: 394,
    },
    {
      title: 'Тип техники',
      dataIndex: 'title',
      width: 721,
      sorter: (a, b) => a.title.localeCompare(b.title),
      key: 'title',
    },
    {
      title: 'Действия',
      key: 'actions',
      width: 198,
      render: (_, record) => (
        <Typography.Link onClick={() => handleOpenDeleteModal(record.id)} underline>
          Удалить
        </Typography.Link>
      ),
    },
  ];

  const [searchText, setSearchText] = useState<string | number>('');
  const [searchedColumn, setSearchedColumn] = useState('');

  const handleSearch = (
    selectedKeys: string[],
    confirm: (param?: FilterConfirmProps) => void,
    dataIndex: keyof CargoCategory,
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setFilteredInfo({});
    setSearchText('');
    setSearchedColumn('');
  };

  const searchInput = useRef<InputRef>(null);
  const getColumnSearchProps = (dataIndex: keyof CargoCategory): ColumnType<CargoCategory> => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Начните вводить для поиска`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button onClick={() => clearFilters && handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Сброс
          </Button>
          <Button
            size="small"
            type="primary"
            icon={<FilterOutlined />}
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText((selectedKeys as string[])[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Фильтровать
          </Button>
        </Space>
      </div>
    ),
    filteredValue: filteredInfo[dataIndex] || null,
    filterIcon: (filtered: boolean) => <FilterOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) => {
      const cellValue = record[dataIndex] ?? '—';
      return cellValue
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase());
    },
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
  });

  const filterableColumns: ColumnsType<CargoCategory> = columns.map((col, index) => {
    const dataIndex = col.key! as keyof CargoCategory;
    if (col.key! === 'actions') {
      return {
        ...col,
      };
    }
    return {
      ...col,
      onHeaderCell: (column) => ({
        width: (column as ColumnType<CargoCategory>)!.width,
      }),
      ...getColumnSearchProps(dataIndex),
      render: col.render,
    };
  });

  return filterableColumns;
};
