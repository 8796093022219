import { Badge, Typography } from 'antd';
import { OrganizationsProps } from '../../../../../Models/Organizations';
import { OrganizationsTableTabs } from '../Table/Component/empty-message';
import { useErrorModal } from '../../../../../Components/Modals/ErrorModal';
import { useGetOperatorOrganizationsCountsQuery } from '../../../../../Store/Slices/api';
import OrganizationsTable from '../Table/OrganizationsTable';

export const getOrganizationsTabsItems = (props: OrganizationsProps) => {
  const { list, loading, totalCount } = props;

  const { data: counts, error } = useGetOperatorOrganizationsCountsQuery();
  const { showErrorModal } = useErrorModal();
  if (error) {
    showErrorModal(error);
  }

  const tabsItems = [
    {
      key: 'all',
      label: <Typography.Text className="organizations__info">Все</Typography.Text>,
      children: (
        <OrganizationsTable
          tabName={OrganizationsTableTabs.ALL}
          organizations={list}
          loading={loading}
          totalCount={totalCount}
        />
      ),
    },
    {
      key: 'not_confirmed',
      label: (
        <Typography.Text className="organizations__info">
          Неподтвержденные организаторы <Badge className={'organizations__badge'} count={counts?.not_confirmed} />
        </Typography.Text>
      ),
      children: (
        <OrganizationsTable
          tabName={OrganizationsTableTabs.UNVERIFIED}
          organizations={list}
          loading={loading}
          totalCount={totalCount}
        />
      ),
    },
    {
      key: 'confirmed',
      label: <Typography.Text className="organizations__info">Подтвержденные организаторы</Typography.Text>,
      children: (
        <OrganizationsTable
          tabName={OrganizationsTableTabs.VERIFIED}
          organizations={list}
          loading={loading}
          totalCount={totalCount}
        />
      ),
    },
    {
      key: 'customers',
      label: <Typography.Text className="organizations__info">Заказчики</Typography.Text>,
      children: (
        <OrganizationsTable
          tabName={OrganizationsTableTabs.CUSTOMERS}
          organizations={list}
          loading={loading}
          totalCount={totalCount}
        />
      ),
    },
  ];

  return tabsItems;
};
