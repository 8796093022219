import { API } from '../../../../Services/axios';
import { Button, Col, Dropdown, MenuProps, Row, Space, Typography } from 'antd';
import { DownOutlined, SettingOutlined } from '@ant-design/icons';
import { clickDownloadLink } from '../../../../Utils/clickDownloadLink';
import { useAppSelector } from '../../../../Store/hooks';
import { useErrorModal } from '../../../../Components/Modals/ErrorModal';
import React, { useState } from 'react';
import TableSettingsModal from '../../../../Components/Modals/TableSettingsModal';

interface ToolbarProps {
  selectedRowKeys: React.Key[];
  showActions: boolean;
  columnsSettings: unknown;
  setColumnsSettings: React.Dispatch<React.SetStateAction<unknown>>;
}

const Toolbar = (props: ToolbarProps) => {
  const { selectedRowKeys, showActions, columnsSettings, setColumnsSettings } = props;
  const { showErrorModal } = useErrorModal();
  const isVerified = useAppSelector((state) => state.user.confirmed);
  const [openSettingsModal, setOpenSettingsModal] = useState<boolean>(false);

  const handleOpen = () => setOpenSettingsModal(true);
  const handleCancel = () => setOpenSettingsModal(false);

  const items: MenuProps['items'] = [
    {
      label: 'Экспортировать',
      key: 'export',
    },
  ];

  const handleMenuClick: MenuProps['onClick'] = (e) => {
    switch (e.key) {
      case 'export':
        API.get(
          `/api/v1/agreement/agreements-csv/?${selectedRowKeys
            .map((rowKey) => {
              return `agreementsIds=${rowKey}&`;
            })
            .join('')
            .slice(0, -1)}`,
          {
            responseType: 'blob',
          },
        )
          .then((response) => {
            const blob = response.data;
            clickDownloadLink(blob, `Список_договоров.csv`);
          })
          .catch((error) => showErrorModal({ error }));
        break;
      default:
        break;
    }
  };

  const menuProps = {
    items,
    onClick: handleMenuClick,
  };

  return (
    <Row className="toolbar__title" justify="space-between">
      <Col>
        <Typography.Title level={2}>Договоры</Typography.Title>
      </Col>
      <Col className="toolbar__btns-panel">
        {selectedRowKeys.length > 0 && isVerified && <Space>{selectedRowKeys.length} выбрано</Space>}
        {showActions && isVerified && (
          <Dropdown menu={menuProps}>
            <Button>
              <Space>
                Действия
                <DownOutlined />
              </Space>
            </Button>
          </Dropdown>
        )}
        <Button
          size={'large'}
          type="primary"
          icon={<SettingOutlined />}
          onClick={() => handleOpen()}
          disabled={!isVerified}
        >
          Настройка колонок
        </Button>
      </Col>
      <TableSettingsModal
        open={openSettingsModal}
        type={'CONTRACT'}
        columnsSettings={columnsSettings}
        setColumnsSettings={setColumnsSettings}
        handleCancel={() => handleCancel()}
      />
    </Row>
  );
};

export default Toolbar;
