import { baseAPI } from './Slices/api';
import { configureStore } from '@reduxjs/toolkit';
import adminOperatorsListReducer from './Slices/Admin/adminOperatorsSlice';
import adminUsersListReducer from './Slices/Admin/adminUsersSlice';
import claimIdReducer from './Slices/claimIdSlice';
import customerContractsColsReduser from './Slices/customerContractsColsSlice';
import customerContractsListReducer from './Slices/Customer/customerContractsSlice';
import customerOrdersColsReducer from './Slices/customerOrdersColsSlice';
import customerOrdersListReducer from './Slices/Customer/customerOrdersSlice';
import operatorContractsListReducer from './Slices/Operator/operatorContractsSlice';
import operatorOrdersListReducer from './Slices/Operator/operatorOrdersSlice';
import operatorOrganizationsListReducer from './Slices/Operator/operatorOrganizationsSlice';
import operatorUsersListReducer from './Slices/Operator/operatorUsersSlice';
import orderReducer from './Slices/orderSlice';
import orgContractsColsReducer from './Slices/orgContractsColsSlice';
import orgOrdersColsReducer from './Slices/orgOrdersColsSlice';
import organizationProfileModalReduser from './Slices/organizationProfileModalSlice';
import organizerContractsListReducer from './Slices/Organizer/organizerContractsSlice';
import organizerOrdersListReducer from './Slices/Organizer/organizerOrdersSlice';
import userReducer from './Slices/userSlice';

export const store = configureStore({
  reducer: {
    user: userReducer,
    order: orderReducer,
    orgOrdersCols: orgOrdersColsReducer,
    customerOrdersCols: customerOrdersColsReducer,
    orgContractsCols: orgContractsColsReducer,
    customerContractsCols: customerContractsColsReduser,
    organizationProfileModal: organizationProfileModalReduser,
    claimId: claimIdReducer,
    [baseAPI.reducerPath]: baseAPI.reducer,
    customerOrdersList: customerOrdersListReducer,
    customerContractsList: customerContractsListReducer,
    organizerOrdersList: organizerOrdersListReducer,
    organizerContractsList: organizerContractsListReducer,
    operatorOrganisationList: operatorOrganizationsListReducer,
    operatorUsersList: operatorUsersListReducer,
    operatorOrdersList: operatorOrdersListReducer,
    operatorContractsList: operatorContractsListReducer,
    adminUsersList: adminUsersListReducer,
    adminOperatorsList: adminOperatorsListReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [
          'orgOrdersCols/getUpdatedOrgColumns',
          'customerOrdersCols/getUpdatedCustomerColumns',
          'customerContractsCols/getUpdatedCustomerColumns',
          'orgContractsCols/getUpdatedOrgColumns',
        ],
        ignoredPaths: ['orgOrdersCols', 'customerOrdersCols', 'orgContractsCols', 'customerContractsCols'],
      },
    }).concat(baseAPI.middleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
