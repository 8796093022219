import { API } from '../Services/axios';
import { getUserInfo } from '../Store/Slices/userSlice';

export const logout = (dispatch) => {
  const parsedStorage = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')!) : {};
  API.get('/api/v1/logout').then(() => {
    localStorage.removeItem('userInfo');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('access_token');
    window.location.replace(`${process.env.REACT_APP_API_URL!}signin`);
  });
  dispatch(
    getUserInfo({
      role: parsedStorage.role,
      name: parsedStorage.name && `${parsedStorage.name} ${parsedStorage.surname}`,
      avatarLink: parsedStorage.avatarLink,
      confirmed: parsedStorage.confirmed,
      phone: parsedStorage.phone,
      organisationId: parsedStorage.organisationId,
    }),
  );
};
