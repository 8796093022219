import { API } from '../../Services/axios';
import { Link, useLocation } from 'react-router-dom';
import { Roles } from '../../Constants/Profile';
import {
  adminMenuItems,
  customerMenuItems,
  landingItems,
  landingUrl,
  operatorMenuItems,
  orgMenuItems,
} from '../../Constants/MenuItems';
import { closeUserMenu, toggleMenu, toggleUserMenu } from '../../Utils/toggleMenu';
import { logout } from '../../Utils/logout';
import { useAppDispatch, useAppSelector } from '../../Store/hooks';
import { useEffect, useState } from 'react';
import { useErrorModal } from '../Modals/ErrorModal';
import logoImg from '../../Assets/Images/header-logo-animated.svg';

const UserHeader = () => {
  const { pathname } = useLocation();
  const { showErrorModal } = useErrorModal();
  const dispatch = useAppDispatch();
  const role = useAppSelector((state) => state.user.role);
  const name = useAppSelector((state) => state.user.name);
  const avatar = useAppSelector((state) => state.user.avatarLink);
  const claimId = useAppSelector((state) => state.claimId.claimId);

  const [operatorCounters, setOperatorCounters] = useState({ claims: 0, orgs: 0 });
  const [customerCounters, setCustomerCounters] = useState({ agreementCount: 0, orderCount: 0 });
  const [orgCounters, setOrgCounters] = useState({ orderCount: 0 });

  useEffect(() => {
    switch (role) {
      case Roles.OPERATOR:
        API.get('/api/v1/operator/counters')
          .then((response) => {
            setOperatorCounters({
              claims: response.data.newClaimsCount,
              orgs: response.data.unconfirmedOrganisationCount,
            });
          })
          .catch((error) => showErrorModal({ error }));
        break;
      case Roles.CUSTOMER:
        API.get('/api/v1/order/counters/')
          .then((response) => {
            setCustomerCounters({
              agreementCount: response.data.agreementCount,
              orderCount: response.data.orderCount,
            });
          })
          .catch((error) => showErrorModal({ error }));
        break;
      case Roles.DELIVERY:
        API.get('/api/v1/offer/priority-offers-count/')
          .then((response) => {
            setOrgCounters({
              orderCount: response.data,
            });
          })
          .catch((error) => showErrorModal({ error }));
        break;
    }
  }, [role, claimId]);

  const roleHeaderMenuItems = (role: Roles) => {
    switch (role) {
      case Roles.CUSTOMER:
        return customerMenuItems(customerCounters.orderCount, customerCounters.agreementCount);
      case Roles.DELIVERY:
        return orgMenuItems(orgCounters.orderCount);
      case Roles.OPERATOR:
        return operatorMenuItems(operatorCounters.orgs, operatorCounters.claims);
      case Roles.ADMIN:
        return adminMenuItems;
    }
  };

  const [scrollY, setScrollY] = useState(0);
  window.addEventListener('scroll', () => setScrollY(window.scrollY));

  const userMenu = document.querySelector('.header__user-menu');
  useEffect(() => {
    const handleOutsideClick = (event) => {
      const target = event.target;

      if (!userMenu) return;

      if (
        !(target as HTMLElement).closest('.header__user-menu') && window.matchMedia('(max-width: 1279px)').matches
          ? !(target as HTMLElement).closest('.header__user')
          : !(target as HTMLElement).closest('.header__user-toggle')
      ) {
        closeUserMenu();
      }
    };

    document.addEventListener('click', handleOutsideClick);

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, [userMenu]);

  return (
    <header className={scrollY > 0 ? 'header header--is-auth scrolled' : 'header header--is-auth'}>
      <div className="header__container">
        <div className="header__wrapper">
          <div className="logo">
            <a href={landingUrl}>
              <img src={logoImg} alt="Verum Logistics" />
            </a>
          </div>
          <div className="header__nav">
            <ul className="header__nav-list">
              {landingItems.map((item) => (
                <li className="header__nav-item" key={item.label}>
                  <a href={item.link}>{item.label}</a>
                </li>
              ))}
            </ul>
            <ul className="header__nav-list">
              {roleHeaderMenuItems(role).map((item) => (
                <li className={`header__nav-item ${pathname === item.key && 'active'}`} key={item.key}>
                  {item.label}
                </li>
              ))}
            </ul>
          </div>
          <div className={avatar ? 'header__user has-icon' : 'header__user'}>
            {avatar ? (
              <div
                className="header__user-icon"
                onClick={(e) => {
                  if (window.matchMedia('(max-width: 1279px)').matches) {
                    toggleUserMenu(e);
                  }
                }}
              >
                <img src={avatar} className="header__user-icon" />
              </div>
            ) : (
              <div
                className="header__user-icon guest"
                onClick={(e) => {
                  if (window.matchMedia('(max-width: 1279px)').matches) {
                    toggleUserMenu(e);
                  }
                }}
              >
                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M15.9599 14.052C15.5809 13.1543 15.031 12.3389 14.3407 11.6513C13.6525 10.9616 12.8373 10.4118 11.94 10.0321C11.932 10.0281 11.9239 10.0261 11.9159 10.022C13.1675 9.11803 13.9811 7.64548 13.9811 5.9841C13.9811 3.23186 11.7512 1.00195 8.99893 1.00195C6.2467 1.00195 4.01679 3.23186 4.01679 5.9841C4.01679 7.64548 4.83041 9.11803 6.08197 10.0241C6.07393 10.0281 6.0659 10.0301 6.05786 10.0341C5.15786 10.4138 4.35027 10.9582 3.65719 11.6533C2.96755 12.3415 2.41769 13.1567 2.038 14.054C1.66498 14.9324 1.46381 15.8741 1.44536 16.8283C1.44483 16.8497 1.44859 16.8711 1.45642 16.891C1.46426 16.911 1.47601 16.9292 1.49099 16.9445C1.50597 16.9599 1.52387 16.9721 1.54363 16.9804C1.5634 16.9887 1.58463 16.993 1.60608 16.993H2.81143C2.89983 16.993 2.97014 16.9227 2.97215 16.8363C3.01233 15.2854 3.63509 13.833 4.73599 12.7321C5.87505 11.593 7.38777 10.9662 8.99893 10.9662C10.6101 10.9662 12.1228 11.593 13.2619 12.7321C14.3628 13.833 14.9855 15.2854 15.0257 16.8363C15.0277 16.9247 15.098 16.993 15.1864 16.993H16.3918C16.4132 16.993 16.4345 16.9887 16.4542 16.9804C16.474 16.9721 16.4919 16.9599 16.5069 16.9445C16.5219 16.9292 16.5336 16.911 16.5414 16.891C16.5493 16.8711 16.553 16.8497 16.5525 16.8283C16.5324 15.868 16.3335 14.9339 15.9599 14.052ZM8.99893 9.43946C8.07684 9.43946 7.20898 9.07986 6.55608 8.42695C5.90317 7.77405 5.54358 6.9062 5.54358 5.9841C5.54358 5.062 5.90317 4.19414 6.55608 3.54124C7.20898 2.88834 8.07684 2.52874 8.99893 2.52874C9.92103 2.52874 10.7889 2.88834 11.4418 3.54124C12.0947 4.19414 12.4543 5.062 12.4543 5.9841C12.4543 6.9062 12.0947 7.77405 11.4418 8.42695C10.7889 9.07986 9.92103 9.43946 8.99893 9.43946Z" />
                </svg>
              </div>
            )}
            <div className="header__user-name">
              <span>{name}</span>
            </div>
            <div className="header__user-toggle" onClick={(e) => toggleUserMenu(e)}>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6 9L12 15L18 9" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </div>
            <div className="header__user-menu">
              <ul className="header__user-menu-list">
                <li className="header__user-menu-item">
                  <Link to="/profile" className={pathname === '/profile' ? 'active' : ''}>
                    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M12.7891 4.10938C10.5799 4.10938 8.78906 5.90024 8.78906 8.10938C8.78906 10.3185 10.5799 12.1094 12.7891 12.1094C14.9982 12.1094 16.7891 10.3185 16.7891 8.10938C16.7891 5.90024 14.9982 4.10938 12.7891 4.10938ZM6.78906 8.10938C6.78906 4.79567 9.47535 2.10938 12.7891 2.10938C16.1028 2.10938 18.7891 4.79567 18.7891 8.10938C18.7891 11.4231 16.1028 14.1094 12.7891 14.1094C9.47535 14.1094 6.78906 11.4231 6.78906 8.10938ZM8.78906 18.1094C7.13221 18.1094 5.78906 19.4525 5.78906 21.1094C5.78906 21.6617 5.34135 22.1094 4.78906 22.1094C4.23678 22.1094 3.78906 21.6617 3.78906 21.1094C3.78906 18.3479 6.02764 16.1094 8.78906 16.1094H16.7891C19.5505 16.1094 21.7891 18.3479 21.7891 21.1094C21.7891 21.6617 21.3413 22.1094 20.7891 22.1094C20.2368 22.1094 19.7891 21.6617 19.7891 21.1094C19.7891 19.4525 18.4459 18.1094 16.7891 18.1094H8.78906Z" />
                    </svg>
                    Профиль
                  </Link>
                </li>
                <li className="header__user-menu-item">
                  <a onClick={() => logout(dispatch)}>
                    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M11.7891 20.5078C11.7891 19.9555 11.3413 19.5078 10.7891 19.5078H5.78906V5.50781H10.7891C11.3413 5.50781 11.7891 5.0601 11.7891 4.50781C11.7891 3.95553 11.3413 3.50781 10.7891 3.50781H5.78906C4.68449 3.50781 3.78906 4.40324 3.78906 5.50781V19.5078C3.78906 20.6124 4.68449 21.5078 5.78906 21.5078H10.7891C11.3413 21.5078 11.7891 21.0601 11.7891 20.5078Z"
                      />
                      <path d="M22.5027 13.2084C22.5953 13.114 22.6655 13.0059 22.7132 12.8906C22.7617 12.7736 22.7887 12.6453 22.7891 12.5108L22.7891 12.5078L22.7891 12.5048C22.7883 12.2499 22.6907 11.9952 22.4962 11.8007L18.4962 7.80071C18.1056 7.41018 17.4725 7.41018 17.082 7.80071C16.6914 8.19123 16.6914 8.82439 17.082 9.21492L19.3748 11.5078H9.78906C9.23678 11.5078 8.78906 11.9555 8.78906 12.5078C8.78906 13.0601 9.23678 13.5078 9.78906 13.5078H19.3748L17.082 15.8007C16.6914 16.1912 16.6914 16.8244 17.082 17.2149C17.4725 17.6054 18.1056 17.6054 18.4962 17.2149L22.4955 13.2156L22.5027 13.2084Z" />
                    </svg>
                    Выход
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <button type="button" className="header__menu-toggle" onClick={toggleMenu}>
            <svg
              className="open"
              width="40"
              height="41"
              viewBox="0 0 40 41"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M5 20.7305H35" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M5 10.7305H35" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M5 30.7305H35" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
            <svg
              className="close"
              width="40"
              height="41"
              viewBox="0 0 40 41"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M30 10.7305L10 30.7305" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M10 10.7305L30 30.7305" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
          </button>
        </div>
      </div>
      <div className="menu">
        <div className="container">
          <ul className="menu__list">
            <li className="menu__item">
              <a href={landingUrl}>На главную</a>
            </li>
            {landingItems.map((item) => (
              <li className="menu__item" key={item.label}>
                <a href={item.link}>{item.label}</a>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </header>
  );
};

export default UserHeader;
