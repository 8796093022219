import { API } from '../../Services/axios';
import { Col, Row } from 'antd';
import { Profile } from '../../Models/Profile';
import { Roles } from '../../Constants/Profile';
import { getUserInfo } from '../../Store/Slices/userSlice';
import { setLocalStorage } from '../../Utils/login';
import { useAppDispatch, useAppSelector } from '../../Store/hooks';
import { useEffect, useState } from 'react';
import { useErrorModal } from '../Modals/ErrorModal';
import { useSearchParams } from 'react-router-dom';
import ChangePassword from './ChangePassword';
import CustomerProfileCard from '../../Modules/Customer/Profile/ProfileCard';
import Loader from '../Loader/Loader';
import OrgProfileCard from '../../Modules/Organizer/Profile/ProfileCard';
import PersonalInfoCard from './PersonalInfoCard';
import ProfileHeader from './ProfileHeader';
import ProfileHistoryCard from './ProfileHistoryCard';
import ProfileMenu from './ProfileMenu';
import ProfileOrgCalcCard from '../../Modules/Organizer/Profile/ProfileOrgCalcCard';
import ProfileOrgCalcSettingCard from '../../Modules/Organizer/Profile/ProfileOrgCalcSettingCard';
import SpecialUserProfile from './SpecialUserProfile';

const ProfileWrapper = () => {
  const { showErrorModal } = useErrorModal();
  const dispatch = useAppDispatch();
  const role = useAppSelector((state) => state.user.role);
  const [searchParams, setSearchParams] = useSearchParams();
  const [current, setCurrent] = useState<number>(1);
  const [examplePrice, setExamplePrice] = useState<number>(0);
  const [profileData, setProfileData] = useState<Profile>();
  const [loading, setLoading] = useState<boolean>(false);
  const [reload, setReload] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    API.get(`/api/v1/profile/settings/example-estimate`)
      .then((response) => {
        setExamplePrice(response.data);
      })
      .catch((error) => showErrorModal({ error }))
      .finally(() => setLoading(false));
    API.get(`/api/v1/profile`)
      .then((response) => {
        setLocalStorage(response.data);
        setProfileData(response.data);
      })
      .catch((error) => showErrorModal({ error }))
      .finally(() => setLoading(false));

    setReload(false);
  }, [reload]);

  const tabNumber = searchParams.get('tab');

  useEffect(() => {
    const activeTab = tabNumber ?? current;
    setCurrent(+activeTab);
  }, [tabNumber]);

  useEffect(() => {
    if (profileData) {
      const updatedUserInfo = {
        role: profileData.type,
        name: `${profileData.name} ${profileData.surname}`,
        avatarLink: profileData.avatar?.link,
        confirmed: profileData.organisation.confirmed,
        phone: profileData.phone,
        organisationId: profileData.organisation.id,
      };
      dispatch(getUserInfo(updatedUserInfo));
    }
  }, [profileData]);

  const notOperatorOrAdmin = role === Roles.DELIVERY || role === Roles.CUSTOMER;

  return (
    <Row justify="center" className="profile__container" gutter={[24, 32]}>
      <Col lg={{ span: 24 }} span={24}>
        <ProfileHeader />
      </Col>
      {notOperatorOrAdmin ? (
        <>
          <Col lg={{ span: 7 }} span={24} className="profile__menu">
            <ProfileMenu current={current} setCurrent={setCurrent} setSearchParams={setSearchParams} />
          </Col>
          <Col lg={{ span: 17 }} span={24} className="profile__card">
            {loading ? (
              <Loader />
            ) : (
              <>
                {current === 1 && (
                  <>
                    {role === Roles.CUSTOMER && profileData && (
                      <CustomerProfileCard profileData={profileData} setReload={setReload} />
                    )}
                    {role === Roles.DELIVERY && profileData && (
                      <OrgProfileCard profileData={profileData} setReload={setReload} />
                    )}
                  </>
                )}
                {current === 2 && profileData && <PersonalInfoCard profileData={profileData} setReload={setReload} />}
                {current === 3 && <ProfileHistoryCard />}
                {current === 4 && role === Roles.DELIVERY && profileData && (
                  <ProfileOrgCalcSettingCard examplePrice={examplePrice} setCurrent={setCurrent} />
                )}
                {current === 5 && role === Roles.DELIVERY && profileData && (
                  <ProfileOrgCalcCard
                    setCurrent={setCurrent}
                    calculatorFeedbackEnabled={profileData.calculatorFeedbackEnabled}
                    setReload={setReload}
                  />
                )}
                {current === 6 && <ChangePassword />}
              </>
            )}
          </Col>
        </>
      ) : (
        <Col span={24} className="profile__card">
          {loading || !profileData ? (
            <Loader />
          ) : (
            <SpecialUserProfile profileData={profileData} setReload={setReload} />
          )}
        </Col>
      )}
    </Row>
  );
};

export default ProfileWrapper;
