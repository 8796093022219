import { Badge, Typography } from 'antd';
import { CustomerTableTabs } from '../Table/Component/empty-message';
import { Dispatch, SetStateAction, useLayoutEffect } from 'react';
import { IContractsTable } from '../../../../../Models/Contract';
import { useErrorModal } from '../../../../../Components/Modals/ErrorModal';
import { useGetCustomerContractsCountsQuery } from '../../../../../Store/Slices/api';
import { useSearchParams } from 'react-router-dom';
import ContractsTable from '../Table/ContractsTable';

interface CustomerContractsProps extends IContractsTable {
  columnsSettings: unknown;
  setTab: Dispatch<SetStateAction<string>>;
  loading: boolean;
}

export const getContractsTabsItems = (props: CustomerContractsProps) => {
  const { list, loading, columnsSettings, setTab, totalCount } = props;
  const { data: counts, error } = useGetCustomerContractsCountsQuery();
  const { showErrorModal } = useErrorModal();
  if (error) {
    showErrorModal(error);
  }

  const [searchParams] = useSearchParams();
  useLayoutEffect(() => {
    if (+searchParams.get('filter')! <= 0 && counts) {
      if (counts.in_process > 0) {
        setTab('in_process');
      } else setTab('all');
    }
  }, [counts]);

  const tabsItems = [
    {
      key: 'all',
      label: (
        <Typography.Text className="contract__info">
          Все <Badge showZero className={'contract__badge-grey'} count={counts?.all ? counts?.all : 0} />
        </Typography.Text>
      ),
      children: (
        <ContractsTable
          columnsSettings={columnsSettings}
          data={list}
          loading={loading}
          tabName={CustomerTableTabs.ALL}
          totalCount={totalCount}
        />
      ),
    },
    {
      key: 'in_process',
      label: (
        <Typography.Text className="contract__info">
          В работе{' '}
          <Badge
            showZero
            className={counts?.in_process === 0 ? 'contract__badge-grey' : 'contract__badge'}
            count={counts?.in_process ? counts?.in_process : 0}
          />
        </Typography.Text>
      ),
      children: (
        <ContractsTable
          columnsSettings={columnsSettings}
          data={list}
          loading={loading}
          tabName={CustomerTableTabs.IN_PROCESS}
          totalCount={totalCount}
        />
      ),
    },
    {
      key: 'delayed',
      label: (
        <Typography.Text className="contract__info">
          Задерживаются{' '}
          <Badge
            showZero
            className={counts?.delayed === 0 ? 'contract__badge-grey' : 'contract__badge'}
            count={counts?.delayed ? counts?.delayed : 0}
          />
        </Typography.Text>
      ),
      children: (
        <ContractsTable
          columnsSettings={columnsSettings}
          data={list}
          loading={loading}
          tabName={CustomerTableTabs.DELAYED}
          totalCount={totalCount}
        />
      ),
    },
    {
      key: 'finished',
      label: (
        <Typography.Text className="contract__info">
          Завершены{' '}
          <Badge showZero className={'contract__badge-grey'} count={counts?.finished ? counts?.finished : 0} />
        </Typography.Text>
      ),
      children: (
        <ContractsTable
          columnsSettings={columnsSettings}
          data={list}
          loading={loading}
          tabName={CustomerTableTabs.COMPLETED}
          totalCount={totalCount}
        />
      ),
    },
  ];

  return tabsItems;
};
