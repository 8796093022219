import { Typography } from 'antd';
import { UsersTabProps } from '../../../../../Models/Profile';
import { UsersTableTabs } from '../Table/Component/empty-message';
import UsersTable from '../Table/UsersTable';

export const getUsersTabsItems = (props: UsersTabProps) => {
  const { list, totalCount, loading } = props;

  const tabsItems = [
    {
      key: 'ALL',
      label: <Typography.Text className="organizations__info">Все</Typography.Text>,
      children: <UsersTable tabName={UsersTableTabs.ALL} users={list} totalCount={totalCount} loading={loading} />,
    },
    {
      key: 'deliveries',
      label: <Typography.Text className="organizations__info">Организаторы</Typography.Text>,
      children: <UsersTable tabName={UsersTableTabs.ORGS} users={list} totalCount={totalCount} loading={loading} />,
    },
    {
      key: 'customers',
      label: <Typography.Text className="organizations__info">Заказчики</Typography.Text>,
      children: (
        <UsersTable tabName={UsersTableTabs.CUSTOMERS} users={list} totalCount={totalCount} loading={loading} />
      ),
    },
  ];

  return tabsItems;
};
