import { FilterValue } from 'antd/lib/table/interface';
import { OrdersTableProps } from '../../../../../../Models/Order';
import { OrganizerTableTabs } from './Component/empty-message';
import { ResizableTitle } from './Component/resizable-title';
import { Table } from 'antd';
import { getCustomerOrdersTableFilter } from './Component/filter';
import { getFilterData } from '../../../../../../Utils/getFilterData';
import { getOrganizerOrdersTableColumns } from './Component/columns';
import {
  resetOrganizerOrdersFilters,
  setOrganizerOrdersFilters,
} from '../../../../../../Store/Slices/Organizer/organizerOrdersSlice';
import { tableLocaleSettings } from './Component/locale';
import { tablePagingSettings } from './Component/paging';
import { useAppDispatch, useAppSelector } from '../../../../../../Store/hooks';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

interface OrgTableProps extends OrdersTableProps {
  columnsSettings: unknown;
  tabName: OrganizerTableTabs;
  totalCount: number;
}

const OrdersTable = (props: OrgTableProps) => {
  const { data, rowSelection, loading, columnsSettings, tabName, totalCount } = props;
  const isVerified = useAppSelector((state) => state.user.confirmed);

  const { filteredInfo, setFilteredInfo, paginationInfo, setPaginationInfo, sortInfo, setSortInfo, handleFilter } =
    getCustomerOrdersTableFilter();
  const dispatch = useAppDispatch();
  const columns = getOrganizerOrdersTableColumns(columnsSettings, filteredInfo, setFilteredInfo);
  const [searchParams, setSearchParams] = useSearchParams();
  const sizePage = localStorage.getItem('sizePage');
  useEffect(() => {
    const handlers = document.querySelectorAll('.react-resizable .react-resizable-handle');
    handlers.forEach((handler) =>
      handler.addEventListener('click', () => {
        return false;
      }),
    );
  });

  useEffect(() => {
    getFilterData(paginationInfo, sortInfo, searchParams, setSearchParams);
    if (filteredInfo) {
      for (const [key, value] of Object.entries(filteredInfo)) {
        if (value !== null) {
          if (typeof value === 'object') {
            if (value[2] === 'gt') {
              dispatch(setOrganizerOrdersFilters({ key: key, value: [value[0]] }));
            } else if (value[2] === 'lt') {
              dispatch(setOrganizerOrdersFilters({ key: `${key}` + 'End', value: [value[0]] }));
            } else if (value[2] === 'eq') {
              const start: { key: string; value: FilterValue } = { key: key, value: [value[0]] };
              const end: { key: string; value: FilterValue } = { key: `${key}` + 'End', value: [value[0]] };
              dispatch(setOrganizerOrdersFilters({ ...start }));
              dispatch(setOrganizerOrdersFilters({ ...end }));
            } else {
              const start: { key: string; value: FilterValue } = { key: key, value: [value[0]] };
              const end: { key: string; value: FilterValue } = { key: `${key}` + 'End', value: [value[1]] };
              dispatch(setOrganizerOrdersFilters({ ...start }));
              dispatch(setOrganizerOrdersFilters({ ...end }));
            }
          } else {
            dispatch(setOrganizerOrdersFilters({ key, value }));
          }
        }
      }
    } else dispatch(resetOrganizerOrdersFilters());
  }, [paginationInfo, sortInfo, filteredInfo]);

  return (
    <Table
      bordered
      components={{
        header: {
          cell: ResizableTitle,
        },
      }}
      rowKey={'id'}
      scroll={{ x: 1300 }}
      loading={loading}
      className="table__orders"
      rowSelection={isVerified ? rowSelection : undefined}
      columns={columns}
      dataSource={data}
      locale={tableLocaleSettings(tabName)}
      pagination={tablePagingSettings(totalCount, sizePage)}
      onChange={handleFilter}
    />
  );
};

export default OrdersTable;
