import { ColumnsType } from 'antd/lib/table';
import { Order } from '../../Models/Order';
import { defaultDateFormat } from '../../Constants/DateFormats';
import { formatPrice } from '../../Utils/formatPrice';
import dayjs from 'dayjs';

const parsedWidth = JSON.parse(localStorage.getItem('orgOrders')!);

export const initialColumns: ColumnsType<Order> = [
  {
    key: 'userFriendlyId',
    title: '№',
    dataIndex: 'userFriendlyId',
    fixed: 'left',
    sorter: true,
    width: parsedWidth ? parsedWidth.userFriendlyId : 120,
  },
  {
    key: 'cargoTypeNameWithParentName',
    title: 'Категория техники',
    dataIndex: 'cargoTypeNameWithParentName',
    ellipsis: true,
    sorter: true,
    width: parsedWidth ? parsedWidth.cargoTypeNameWithParentName : 300,
  },
  {
    key: 'cityFrom',
    title: 'Город отправки',
    dataIndex: 'cityFrom',
    ellipsis: true,
    sorter: true,
    render: (_, record) =>
      `${
        record.regionFrom && record.regionFrom !== record.cityFrom
          ? record.cityFrom + `, ` + record.regionFrom
          : record.cityFrom
      }`,
    width: parsedWidth ? parsedWidth.cityFrom : 220,
  },
  {
    key: 'cityTo',
    title: 'Город доставки',
    dataIndex: 'cityTo',
    ellipsis: true,
    sorter: true,
    render: (_, record) =>
      `${
        record.regionTo && record.regionTo !== record.cityTo ? record.cityTo + `, ` + record.regionTo : record.cityTo
      }`,
    width: parsedWidth ? parsedWidth.cityTo : 220,
  },
  {
    key: 'distance',
    title: 'Расстояние',
    dataIndex: 'distance',
    align: 'right',
    ellipsis: true,
    sorter: true,
    render: (text) => `${text} км`,
    width: parsedWidth ? parsedWidth.distance : 155,
  },
  {
    key: 'dateArrival',
    title: 'Дата получения',
    dataIndex: 'dateArrival',
    ellipsis: true,
    sorter: true,
    render: (text) => dayjs(text).format(defaultDateFormat),
    width: parsedWidth ? parsedWidth.dateArrival : 145,
  },
  {
    key: 'price',
    title: 'Цена',
    dataIndex: 'price',
    align: 'right',
    ellipsis: true,
    sorter: true,
    render: (_, record) => `${formatPrice(+record.price)} ₽`,
    width: parsedWidth ? parsedWidth.price : 143,
  },
  {
    key: 'machineName',
    title: 'Модель техники',
    ellipsis: true,
    dataIndex: 'machineName',
    sorter: true,
    width: parsedWidth ? parsedWidth.machineName : 235,
  },
  {
    key: 'machinePrice',
    title: 'Стоимость техники',
    dataIndex: 'machinePrice',
    align: 'right',
    ellipsis: true,
    sorter: true,
    render: (_, record) => `${formatPrice(+record.machinePrice)} ₽`,
    width: parsedWidth ? parsedWidth.machinePrice : 155,
  },
  {
    key: 'dateLoading',
    title: 'Дата погрузки техники',
    dataIndex: 'dateLoading',
    ellipsis: true,
    sorter: true,
    render: (text) => dayjs(text).format(defaultDateFormat),
    width: parsedWidth && parsedWidth.dateLoading ? parsedWidth.dateLoading : 135,
  },
  {
    key: 'acceptedAgreementUserFriendlyId',
    title: 'Мой номер договора',
    dataIndex: 'acceptedAgreementUserFriendlyId',
    ellipsis: true,
    sorter: true,
    width:
      parsedWidth && parsedWidth.acceptedAgreementUserFriendlyId ? parsedWidth.acceptedAgreementUserFriendlyId : 175,
  },
  {
    key: 'organisationName',
    title: 'Заказчик',
    dataIndex: 'organisationName',
    ellipsis: true,
    sorter: true,
    width: parsedWidth && parsedWidth.organisationName ? parsedWidth.organisationName : 145,
  },
  {
    key: 'weight',
    title: 'Вес',
    dataIndex: 'weight',
    align: 'right',
    ellipsis: true,
    sorter: true,
    render: (_, record) => `${record.weight} кг`,
    width: parsedWidth ? parsedWidth.weight : 104,
  },
  {
    key: 'length',
    title: 'Длина',
    dataIndex: 'length',
    align: 'right',
    ellipsis: true,
    sorter: true,
    render: (_, record) => `${record.length} м`,
    width: parsedWidth ? parsedWidth.length : 115,
  },
  {
    key: 'width',
    title: 'Ширина',
    dataIndex: 'width',
    align: 'right',
    ellipsis: true,
    sorter: true,
    render: (_, record) => `${record.width} м`,
    width: parsedWidth ? parsedWidth.width : 125,
  },
  {
    key: 'height',
    title: 'Высота',
    dataIndex: 'height',
    align: 'right',
    ellipsis: true,
    sorter: true,
    render: (_, record) => `${record.height} м`,
    width: parsedWidth ? parsedWidth.height : 123,
  },
  {
    key: 'comment',
    title: 'Комментарий',
    dataIndex: 'comment',
    ellipsis: true,
    sorter: true,
    width: parsedWidth ? parsedWidth.comment : 322,
    render: (text) => (text ? text : '—'),
  },
  {
    key: 'createdAt',
    title: 'Дата создания',
    dataIndex: 'createdAt',
    ellipsis: { showTitle: false },
    sorter: true,
    render: (text) => dayjs(text).format(defaultDateFormat),
    width: parsedWidth ? parsedWidth.createdAt : 165,
  },
];
