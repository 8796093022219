import { ContractsTableProps } from '../../../../../Models/Contract';
import { CustomerTableTabs } from './Component/empty-message';
import { ResizableTitle } from './Component/resizable-title';
import { Table } from 'antd';
import { getCustomerContractsTableColumns } from './Component/columns';
import { getCustomerContractsTableFilter } from './Component/filters';
import {
  resetCustomerContractsFilters,
  setCustomerContractsFilters,
} from '../../../../../Store/Slices/Customer/customerContractsSlice';

import { FilterValue } from 'antd/lib/table/interface';
import { getFilterData } from '../../../../../Utils/getFilterData';
import { tableLocaleSettings } from './Component/locale';
import { tablePagingSettings } from './Component/paging';
import { updateOrganisationId } from '../../../../../Store/Slices/organizationProfileModalSlice';
import { useAppDispatch } from '../../../../../Store/hooks';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import OrganizaionInfoModal from '../../../../../Components/Modals/OrganizationInfoModal';

interface CustomerTableProps extends Omit<ContractsTableProps, 'rowSelection'> {
  columnsSettings: unknown;
  tabName: CustomerTableTabs;
  totalCount: number;
}

const ContractsTable = (props: CustomerTableProps) => {
  const { data, loading, columnsSettings, tabName, totalCount } = props;
  const dispatch = useAppDispatch();

  const [openModal, setOpenModal] = useState<boolean>(false);
  const showModal = (organisationId: number) => {
    dispatch(updateOrganisationId(organisationId));
    setOpenModal(true);
  };
  const hideModal = () => setOpenModal(false);

  const { filteredInfo, setFilteredInfo, handleFilter, paginationInfo, setPaginationInfo, sortInfo, setSortInfo } =
    getCustomerContractsTableFilter();
  const columns = getCustomerContractsTableColumns(columnsSettings, filteredInfo, setFilteredInfo, showModal);

  const [searchParams, setSearchParams] = useSearchParams();

  const sizePage = localStorage.getItem('sizePage');

  useEffect(() => {
    const handlers = document.querySelectorAll('.react-resizable .react-resizable-handle');
    handlers.forEach((handler) =>
      handler.addEventListener('click', () => {
        return false;
      }),
    );
  });

  useEffect(() => {
    getFilterData(paginationInfo, sortInfo, searchParams, setSearchParams);
    if (filteredInfo) {
      for (const [key, value] of Object.entries(filteredInfo)) {
        if (value !== null) {
          if (typeof value === 'object') {
            if (value[2] === 'gt') {
              dispatch(setCustomerContractsFilters({ key: key, value: [value[0]] }));
            } else if (value[2] === 'lt') {
              dispatch(setCustomerContractsFilters({ key: `${key}` + 'End', value: [value[0]] }));
            } else if (value[2] === 'eq') {
              const start: { key: string; value: FilterValue } = { key: key, value: [value[0]] };
              const end: { key: string; value: FilterValue } = { key: `${key}` + 'End', value: [value[0]] };
              dispatch(setCustomerContractsFilters({ ...start }));
              dispatch(setCustomerContractsFilters({ ...end }));
            } else {
              const start: { key: string; value: FilterValue } = { key: key, value: [value[0]] };
              const end: { key: string; value: FilterValue } = { key: `${key}` + 'End', value: [value[1]] };
              dispatch(setCustomerContractsFilters({ ...start }));
              dispatch(setCustomerContractsFilters({ ...end }));
            }
          } else {
            dispatch(setCustomerContractsFilters({ key, value }));
          }
        }
      }
    } else dispatch(resetCustomerContractsFilters());
  }, [paginationInfo, sortInfo, filteredInfo]);

  return (
    <>
      <Table
        bordered
        rowKey={'agreementId'}
        className="table__contracts"
        components={{
          header: {
            cell: ResizableTitle,
          },
        }}
        scroll={{ x: 1300 }}
        loading={loading}
        columns={columns}
        dataSource={data}
        locale={tableLocaleSettings(tabName)}
        pagination={tablePagingSettings(totalCount, sizePage)}
        onChange={handleFilter}
      />
      <OrganizaionInfoModal title="Данные об организаторе перевозки" open={openModal} handleCancel={hideModal} />
    </>
  );
};

export default ContractsTable;
