import { Contract } from '../../../../../Models/Contract';
import { TablePaginationConfig, TableProps } from 'antd';
import { useState } from 'react';

export const getOperatorsTableFilter = () => {
  const [paginationInfo, setPaginationInfo] = useState<TablePaginationConfig>();

  const handleFilter: TableProps<Contract>['onChange'] = (pagination, filters) => {
    setPaginationInfo(pagination);
  };

  return {
    paginationInfo,
    setPaginationInfo,
    handleFilter,
  };
};
