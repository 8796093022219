import { Badge, Checkbox, Typography } from 'antd';
import { Contract, ContractTableOrganizerProps } from '../../../../../Models/Contract';
import { OrgTableTabs } from '../Table/Component/empty-message';
import { useErrorModal } from '../../../../../Components/Modals/ErrorModal';
import { useGetOrganizerContractsCountsQuery } from '../../../../../Store/Slices/api';
import { useSearchParams } from 'react-router-dom';
import ContractsTable from '../Table/ContractsTable';
import React, { Dispatch, SetStateAction, useLayoutEffect } from 'react';

interface OrganizerContractsProps extends ContractTableOrganizerProps {
  setShowActions: React.Dispatch<React.SetStateAction<boolean>>;
  selectedRowKeys: React.Key[];
  setSelectedRowKeys: React.Dispatch<React.SetStateAction<React.Key[]>>;
  columnsSettings: unknown;
  setTab: Dispatch<SetStateAction<string>>;
  loading: boolean;
}

export const getContractsTabsItems = (props: OrganizerContractsProps) => {
  const { list, totalCount, setShowActions, selectedRowKeys, setSelectedRowKeys, loading, columnsSettings, setTab } =
    props;
  const { data: counts, error } = useGetOrganizerContractsCountsQuery();
  const { showErrorModal } = useErrorModal();
  if (error) {
    showErrorModal(error);
  }

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    newSelectedRowKeys.length > 0 ? setShowActions(true) : setShowActions(false);
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const toggleSelectAll = (contracts: Contract[]) => {
    setSelectedRowKeys((keys) => (keys.length === contracts.length ? [] : contracts.map((r) => r.agreementId)));
  };

  const headerCheckbox = (contracts: Contract[]) => (
    <Checkbox
      checked={!!selectedRowKeys.length}
      indeterminate={selectedRowKeys.length > 0 && selectedRowKeys.length < contracts.length}
      onChange={(e) => {
        setShowActions(e.target.checked);
        toggleSelectAll(contracts);
      }}
    />
  );

  const rowSelection = (contracts: Contract[]) => ({
    selectedRowKeys,
    onChange: onSelectChange,
    type: 'checkbox',
    columnTitle: headerCheckbox(contracts),
  });

  const [searchParams] = useSearchParams();
  useLayoutEffect(() => {
    if (+searchParams.get('filter')! <= 0 && counts) {
      if (counts.in_process! > 0) {
        setTab('in_process');
      } else if (counts.awaits_payment > 0) {
        setTab('awaits_payment');
      } else setTab('all');
    }
  }, [list]);

  const tabsItems = [
    {
      key: 'all',
      label: <Typography.Text className="contract__info">Все</Typography.Text>,
      children: (
        <ContractsTable
          columnsSettings={columnsSettings}
          data={list}
          rowSelection={rowSelection(list)}
          loading={loading}
          tabName={OrgTableTabs.ALL}
          totalCount={totalCount}
        />
      ),
    },
    {
      key: 'in_process',
      label: (
        <Typography.Text className="contract__info">
          В работе{' '}
          <Badge
            showZero
            className={counts?.in_process === 0 ? 'contract__badge-grey' : 'contract__badge'}
            count={counts?.in_process}
          />
        </Typography.Text>
      ),
      children: (
        <ContractsTable
          columnsSettings={columnsSettings}
          data={list}
          rowSelection={rowSelection(list)}
          loading={loading}
          tabName={OrgTableTabs.IN_PROCESS}
          totalCount={totalCount}
        />
      ),
    },
    {
      key: 'awaits_payment',
      label: (
        <Typography.Text className="contract__info">
          Ожидают поступления оплаты{' '}
          <Badge
            showZero
            className={counts?.awaits_payment === 0 ? 'contract__badge-grey' : 'contract__badge'}
            count={counts?.awaits_payment}
          />
        </Typography.Text>
      ),
      children: (
        <ContractsTable
          columnsSettings={columnsSettings}
          data={list}
          rowSelection={rowSelection(list)}
          loading={loading}
          tabName={OrgTableTabs.AWAITS_PAYMENT}
          totalCount={totalCount}
        />
      ),
    },
    {
      key: 'finished',
      label: (
        <Typography.Text className="contract__info">
          Завершены <Badge className={'contract__badge-grey'} count={counts?.finished} />
        </Typography.Text>
      ),
      children: (
        <ContractsTable
          columnsSettings={columnsSettings}
          data={list}
          rowSelection={rowSelection(list)}
          loading={loading}
          tabName={OrgTableTabs.COMPLETED}
          totalCount={totalCount}
        />
      ),
    },
  ];

  return tabsItems;
};
