import { Col, Row, Table } from 'antd';
import { getOperatorsTableColumns } from './Component/columns';
import { getOperatorsTableFilter } from './Component/filters';

import { tableLocaleSettings } from './Component/locale';
import { tablePagingSettings } from './Component/paging';
import { useAppDispatch, useAppSelector } from '../../../../Store/hooks';
import { useEffect, useState } from 'react';
import { useErrorModal } from '../../../../Components/Modals/ErrorModal';
import { useGetAdminOperatorsQuery } from '../../../../Store/Slices/api';
import { useSearchParams } from 'react-router-dom';
import Toolbar from './Toolbar';

const OperatorsTable = () => {
  const { showErrorModal } = useErrorModal();
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  const [searchParams, setSearchParams] = useSearchParams();
  const page = searchParams.get('page') ? searchParams.get('page') : '1';
  const size = searchParams.get('size') ? searchParams.get('size') : localStorage.getItem('sizePage');

  const params = { filter: {}, search: {} };
  params.search = useAppSelector((state) => state.adminOperatorsList);

  searchParams.forEach((value, key) => {
    params.filter[key] = value;
  });

  const { data, isLoading, error } = useGetAdminOperatorsQuery(params);
  if (error) {
    showErrorModal(error);
  }

  useEffect(() => {
    setLoading(isLoading);
  }, [isLoading]);

  useEffect(() => {
    if (size) {
      localStorage.setItem('sizePage', size);
    }
    if (page && size) {
      setSearchParams((params) => {
        params.set('page', page);
        return searchParams;
      });
      setSearchParams((params) => {
        params.set('size', size);
        return searchParams;
      });
    }
  }, [page, size]);

  const sizePage = localStorage.getItem('sizePage');

  const columns = getOperatorsTableColumns();

  const { paginationInfo, setPaginationInfo, handleFilter } = getOperatorsTableFilter();

  useEffect(() => {
    if (paginationInfo?.current) {
      setSearchParams((params) => {
        params.set('page', paginationInfo.current ? paginationInfo.current.toString() : '');
        return searchParams;
      });
    }
    if (paginationInfo?.pageSize) {
      setSearchParams((params) => {
        params.set('size', paginationInfo.pageSize ? paginationInfo.pageSize.toString() : '');
        return searchParams;
      });
    }
  }, [paginationInfo]);
  return (
    <div>
      <Row justify="space-between">
        <Col span={24}>
          <Toolbar />
        </Col>
      </Row>
      <div>
        <Table
          bordered
          rowKey={'id'}
          className="table__organizations"
          loading={loading}
          scroll={{ x: 1331 }}
          columns={columns}
          dataSource={data?.list}
          locale={tableLocaleSettings}
          pagination={tablePagingSettings(data?.totalCount ?? 0, sizePage)}
        />
      </div>
    </div>
  );
};

export default OperatorsTable;
