import { Badge, Checkbox, Typography } from 'antd';
import { CatalogTableTabs } from '../Table/Component/empty-message';
import { CloseCircleOutlined } from '@ant-design/icons';
import { IOrderTable, Order } from '../../../../../../Models/Order';
import { filterDate } from '../../../../../../Components/Filters/DateFilter';
import { filterNumber } from '../../../../../../Components/Filters/NumberFilter';
import { filterString } from '../../../../../../Components/Filters/StringFilter';
import { getOrderPropertyType } from '../../../../../../Utils/getOrderPropertyType';
import OrdersTable from '../Table/OrdersTable';
import React from 'react';

interface OrganizerOrdersProps extends IOrderTable {
  columnsSettings: unknown;
  loading: boolean;
}
// : OrganizerOrdersProps,
//   savedTabs: unknown,
//   : React.Dispatch<React.SetStateAction<boolean>>,
//   setShowFilterActions: React.Dispatch<React.SetStateAction<boolean>>,
//   setFilterInfo: React.Dispatch<React.SetStateAction<Record<string, FilterValue | null>>>,
//   selectedRowKeys: React.Key[],
//   setSelectedRowKeys: React.Dispatch<React.SetStateAction<React.Key[]>>,
//   : (id: number, name: string) => void,
export const getOrdersTabsItems = (props) => {
  const {
    list,
    columnsSettings,
    loading,
    totalCount,
    savedTabs,
    setShowActions,
    setShowFilterActions,
    setFilterInfo,
    selectedRowKeys,
    setSelectedRowKeys,
    handleOpenDeleteSavedFilterModal,
  } = props;

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    newSelectedRowKeys.length > 0 ? setShowActions(true) : setShowActions(false);
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const toggleSelectAll = (orders: Order[]) => {
    setSelectedRowKeys((keys) => (keys.length === orders.length ? [] : orders.map((r) => r.id)));
  };

  const headerCheckbox = (orders: Order[]) => (
    <Checkbox
      checked={!!selectedRowKeys.length}
      indeterminate={selectedRowKeys.length > 0 && selectedRowKeys.length < orders.length}
      onChange={(e) => {
        setShowActions(e.target.checked);
        toggleSelectAll(orders);
      }}
    />
  );

  const rowSelection = (orders: Order[]) => ({
    selectedRowKeys,
    onChange: onSelectChange,
    type: 'checkbox',
    columnTitle: headerCheckbox(orders),
  });

  const tabsItems = [
    {
      key: '1',
      label: (
        <Typography.Text className="order__info">
          Все
          <Badge
            showZero
            className={totalCount === 0 ? 'order__badge-grey' : 'order__badge'}
            count={totalCount}
            overflowCount={999}
          />
        </Typography.Text>
      ),
      children: (
        <OrdersTable
          columnsSettings={columnsSettings}
          data={list}
          rowSelection={rowSelection(list)}
          setShowFilterActions={setShowFilterActions}
          setFilterInfo={setFilterInfo}
          loading={loading}
          tabName={CatalogTableTabs.ALL}
          totalCount={totalCount!}
        />
      ),
    },
  ];
  savedTabs &&
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (savedTabs as Array<any>).forEach((tab, index) => {
      let filteredOrders = list;
      Object.keys(tab).forEach((filter) => {
        if (filter !== 'id' && filter !== 'name' && filter !== 'executorId') {
          filteredOrders = filteredOrders.filter((order) => {
            if (`${getOrderPropertyType(filter)}` === 'string' || filter === 'userFriendlyId')
              return filterString(order[filter], tab[filter]![0]);
            else if (`${getOrderPropertyType(filter)}` === 'number') return filterNumber(order[filter], tab[filter]!);
            else if (`${getOrderPropertyType(filter)}` === 'object') return filterDate(order[filter], tab[filter]!);
          });
        }
      });
      tabsItems.push({
        key: `${index + 2}`,
        label: (
          <div>
            <Typography.Text className="order__info">
              {tab.name}
              <Badge
                showZero
                className={filteredOrders.length === 0 ? 'order__badge-grey' : 'order__badge'}
                count={filteredOrders.length}
              />
            </Typography.Text>
            <CloseCircleOutlined
              onClick={() => handleOpenDeleteSavedFilterModal(tab.id, tab.name)}
              style={{ color: 'black', marginTop: '5px', paddingLeft: '2px' }}
            />
          </div>
        ),
        children: (
          <OrdersTable
            columnsSettings={columnsSettings}
            data={filteredOrders}
            rowSelection={rowSelection(filteredOrders)}
            setShowFilterActions={setShowFilterActions}
            setFilterInfo={setFilterInfo}
            loading={loading}
            tabName={CatalogTableTabs.ALL}
            totalCount={filteredOrders.length}
          />
        ),
      });
    });

  return tabsItems;
};
