import { API } from '../../../../../Services/axios';
import { Tabs } from 'antd';
import { getOrdersTabsItems } from './Component/tabs-items';
import { useAppSelector } from '../../../../../Store/hooks';
import { useErrorModal } from '../../../../../Components/Modals/ErrorModal';
import { useGetOrganizerOrdersQuery } from '../../../../../Store/Slices/api';
import { useSearchParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import Toolbar from './Toolbar';
import UnverifiedOrgAlert from '../../Detail/Component/unverified-alert';

const Orders = () => {
  const { showErrorModal } = useErrorModal();
  const [showActions, setShowActions] = useState(false);
  // const [data, setData] = useState<OrderTableProps>({
  //   awaits: 0,
  //   offered: 0,
  //   awaits_agreement: 0,
  //   pre_agreement_loaded: 0,
  //   accepted: 0,
  //   signed: 0,
  //   archive: 0,
  //   orders: [],
  // });
  const isVerified = useAppSelector((state) => state.user.confirmed);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  const [searchParams, setSearchParams] = useSearchParams();
  const [columnsSettings, setColumnsSettings] = useState<unknown>(null);
  const [activeTab, setActiveTab] = useState<string>(
    searchParams.get('filter')! !== '' ? searchParams.get('filter')! : 'awaits',
  );
  const page = searchParams.get('page') ? searchParams.get('page') : '1';
  const size = searchParams.get('size') ? searchParams.get('size') : localStorage.getItem('sizePage');
  const sort = searchParams.get('sort') ? searchParams.get('sort') : 'descend';
  const sortField = searchParams.get('sortField') ? searchParams.get('sortField') : 'createdAt';

  const params = { filter: {}, search: {} };
  const paramsSearch = useAppSelector((state) => state.organizerOrdersList);
  params.search = paramsSearch;

  searchParams.forEach((value, key) => {
    params.filter[key] = value;
  });
  const { data, isLoading, error } = useGetOrganizerOrdersQuery(params);

  if (error) {
    showErrorModal(error);
  }

  useEffect(() => {
    API.get(`/api/v1/table-settings/order`)
      .then((response) => setColumnsSettings(response.data))
      .catch((error) => showErrorModal({ error }));
  }, []);

  useEffect(() => {
    if (size) {
      localStorage.setItem('sizePage', size);
    }
    if (page && size && activeTab) {
      setSearchParams((params) => {
        params.set('page', page);
        return searchParams;
      });
      setSearchParams((params) => {
        params.set('size', size);
        return searchParams;
      });
      setSearchParams((params) => {
        params.set('filter', activeTab);
        return searchParams;
      });
    }
    if (sort && sortField) {
      setSearchParams((params) => {
        params.set('sort', sort);
        return searchParams;
      });
      setSearchParams((params) => {
        params.set('sortField', sortField);
        return searchParams;
      });
    }
  }, [activeTab, page, size, sort, sortField]);

  const ordersProps = {
    columnsSettings: columnsSettings,
    setShowActions: setShowActions,
    selectedRowKeys: selectedRowKeys,
    setSelectedRowKeys: setSelectedRowKeys,
    setActiveTab: setActiveTab,
    loading: isLoading,
    list: data?.list,
    totalCount: data?.totalCount,
  };

  const tabsItems = getOrdersTabsItems(ordersProps);

  return (
    <div>
      {!isVerified && (
        <div className="profile__unverified">
          <UnverifiedOrgAlert />
        </div>
      )}
      <Toolbar
        activeTab={activeTab}
        selectedRowKeys={selectedRowKeys}
        showActions={showActions}
        columnsSettings={columnsSettings}
        setColumnsSettings={setColumnsSettings}
      />
      <div className="orders__tabs-table">
        <Tabs
          activeKey={`${activeTab}`}
          items={tabsItems}
          className="tabs"
          onChange={(e) => {
            setShowActions(false);
            setSelectedRowKeys([]);
            setActiveTab(e);
            setSearchParams((params) => {
              params.set('page', '1');
              return searchParams;
            });
            window.history.replaceState(
              null,
              '',
              `/organizer/orders/in-progress?filter=${e}&page=${page}&size=${size}`,
            );
          }}
        />
      </div>
    </div>
  );
};

export default Orders;
