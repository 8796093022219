import { SelectOption } from '../Models/CargoCategories';

export const typeSelectOptions: SelectOption[] = [
  {
    value: 'LEGAL',
    label: 'Юридическое лицо',
  },
  {
    value: 'INDIVIDUAL',
    label: 'ИП',
  },
];

export const roleSelectOptions: SelectOption[] = [
  {
    value: 'CUSTOMER',
    label: 'Заказчик',
  },
  {
    value: 'DELIVERY',
    label: 'Организатор',
  },
];

export const confirmedSelectOptions: SelectOption[] = [
  {
    value: 'true',
    label: 'Подтверждена',
  },
  {
    value: 'false',
    label: 'Не подтверждена',
  },
];

export const lockedSelectOptions: SelectOption[] = [
  {
    value: 'true',
    label: 'Заблокирована',
  },
  {
    value: 'false',
    label: 'Активна',
  },
];
