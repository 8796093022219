import { ColumnType, FilterConfirmProps, FilterValue } from 'antd/lib/table/interface';
import { ColumnsType } from 'antd/lib/table';
import { FilterOutlined } from '@ant-design/icons';
import { InputRef, Typography } from 'antd';
import { Profile } from '../../../../../../Models/Profile';
import { Roles } from '../../../../../../Constants/Profile';
import { SelectFilter } from '../../../../../../Components/Filters/SelectFilter';
import { StringFilter } from '../../../../../../Components/Filters/StringFilter';
import { UserPropertyType } from '../../../../../../Utils/getUserPropertyType';
import { defaultDateFormat } from '../../../../../../Constants/DateFormats';
import { isUndefined } from 'lodash';
import { resetAdminUsersFilters, setAdminUsersFilters } from '../../../../../../Store/Slices/Admin/adminUsersSlice';
import { roleSelectOptions } from '../../../../../../Constants/OrganizationSelectOptions';
import { useAppDispatch } from '../../../../../../Store/hooks';
import { useRef, useState } from 'react';
import dayjs from 'dayjs';

export const getAdminUsersTableColumns = (
  filteredInfo: Record<string, FilterValue | null>,
  setFilteredInfo: React.Dispatch<React.SetStateAction<Record<string, FilterValue | null>>>,
) => {
  type DataIndex = keyof UserPropertyType;
  const columns: ColumnsType<Profile> = [
    {
      title: 'ФИО пользователя',
      dataIndex: 'surname',
      width: 230,
      ellipsis: true,
      sorter: true,
      key: 'surname',
      render: (_, record) => (
        <Typography.Link href={`/admin/users/${record.id}`} underline>
          {record.patronymic
            ? `${record.surname} ${record.name} ${record.patronymic}`
            : `${record.surname} ${record.name}`}
        </Typography.Link>
      ),
    },
    {
      title: 'Тип пользователя',
      dataIndex: 'type',
      width: 212,
      ellipsis: true,
      sorter: true,
      render: (_, record) => (record.type === Roles.CUSTOMER ? 'Заказчик' : 'Организатор'),
      key: 'type',
    },
    {
      title: 'Дата регистрации',
      dataIndex: 'createdAt',
      width: 230,
      ellipsis: true,
      sorter: true,
      key: 'createdAt',
      render: (text) => dayjs(text).format(defaultDateFormat),
    },
    {
      title: 'Статус',
      dataIndex: 'enabled',
      width: 195,
      ellipsis: true,
      sorter: true,
      key: 'enabled',
      render: (_, record) => (record.enabled || isUndefined(record.enabled) ? 'Активен' : 'Заблокирован'),
    },
    {
      title: 'Электронная почта',
      dataIndex: 'email',
      width: 207,
      ellipsis: true,
      key: 'email',
    },
    {
      title: 'Организация',
      dataIndex: 'organisationName',
      width: 'auto',
      ellipsis: true,
      key: 'organisationName',
      render: (_, record) => <Typography.Text>{record.organisation.shortName}</Typography.Text>,
    },
  ];

  const dispatch = useAppDispatch();
  const [searchText, setSearchText] = useState<string | number>('');
  const [searchedColumn, setSearchedColumn] = useState('');

  const handleSearch = (
    selectedKeys: string[],
    confirm: (param?: FilterConfirmProps) => void,
    dataIndex: DataIndex,
  ) => {
    confirm();
    setSearchText(selectedKeys.toString());
    setSearchedColumn(dataIndex);
    dispatch(setAdminUsersFilters({ key: dataIndex, value: selectedKeys }));
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setFilteredInfo({});
    setSearchText('');
    setSearchedColumn('');
    dispatch(resetAdminUsersFilters());
  };

  const searchInput = useRef<InputRef>(null);

  const getColumnSearchProps = (dataIndex: DataIndex): ColumnType<Profile> => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
      return dataIndex === 'type' ? (
        <SelectFilter
          selectedKeys={selectedKeys as string[]}
          setSelectedKeys={setSelectedKeys}
          handleSearch={handleSearch}
          handleReset={handleReset}
          confirm={confirm}
          dataIndex={dataIndex}
          clearFilters={clearFilters}
          setSearchText={setSearchText}
          setSearchedColumn={setSearchedColumn}
          selectOptions={roleSelectOptions}
        />
      ) : (
        <StringFilter
          searchInput={searchInput}
          selectedKeys={selectedKeys as string[]}
          setSelectedKeys={setSelectedKeys}
          handleSearch={handleSearch}
          handleReset={handleReset}
          confirm={confirm}
          dataIndex={dataIndex}
          clearFilters={clearFilters}
          setSearchText={setSearchText}
          setSearchedColumn={setSearchedColumn}
        />
      );
    },
    filteredValue: filteredInfo[dataIndex] || null,
    filterIcon: (filtered: boolean) => <FilterOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
  });

  const filterableColumns: ColumnsType<Profile> = columns.map((col, index) => {
    const dataIndex = col.key! as keyof Omit<Profile, 'avatar' | 'organisation'>;
    if (dataIndex === 'createdAt' || dataIndex === 'enabled') {
      return {
        ...col,
      };
    }
    return {
      ...col,
      ...getColumnSearchProps(dataIndex),
      render: col.render,
    };
  });

  return filterableColumns;
};
