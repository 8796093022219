import { Badge, Typography } from 'antd';
import { CustomerTableTabs } from '../Table/Component/empty-message';
import { Dispatch, SetStateAction, useLayoutEffect } from 'react';
import { IOrderTable } from '../../../../../Models/Order';
import { useErrorModal } from '../../../../../Components/Modals/ErrorModal';
import { useGetCustomerOrdersCountsQuery } from '../../../../../Store/Slices/api';
import { useSearchParams } from 'react-router-dom';
import OrdersTable from '../Table/OrdersTable';

interface CustomerOrdersProps extends IOrderTable {
  columnsSettings: unknown;
  setTab: Dispatch<SetStateAction<string>>;
  loading: boolean;
}

export const getOrdersTabsItems = (props: CustomerOrdersProps) => {
  const { list, totalCount, columnsSettings, setTab, loading } = props;
  const { data: counts, error } = useGetCustomerOrdersCountsQuery();
  const { showErrorModal } = useErrorModal();
  if (error) {
    showErrorModal(error);
  }
  const [searchParams] = useSearchParams();
  useLayoutEffect(() => {
    if (+searchParams.get('filter')! <= 0 && counts) {
      if (counts.with_draft_agreements > 0) {
        setTab('with_draft_agreements');
      } else if (counts.without_offers > 0) {
        setTab('without_offers');
        // } else if (ordersAtSigning.length > 0) {
        //   setTab('signed');
      } else if (counts.with_offers > 0) {
        setTab('with_offers');
      } else if (counts.drafts > 0) {
        setTab('drafts');
      } else if (counts.signed > 0) {
        setTab('signed');
      } else setTab('ALL');
    }
  }, [counts]);

  const tabsItems = [
    {
      key: 'ALL',
      label: (
        <Typography.Text className="order__info">
          Все <Badge showZero className={'order__badge-grey'} count={counts?.all ? counts?.all : 0} />
        </Typography.Text>
      ),
      children: (
        <OrdersTable
          columnsSettings={columnsSettings}
          data={list}
          loading={loading}
          tabName={CustomerTableTabs.ALL}
          totalCount={totalCount ? totalCount : 0}
        />
      ),
    },
    {
      key: 'without_offers',
      label: (
        <Typography.Text className="order__info">
          Без предложений{' '}
          <Badge showZero className={'order__badge-grey'} count={counts?.without_offers ? counts?.without_offers : 0} />
        </Typography.Text>
      ),
      children: (
        <OrdersTable
          columnsSettings={columnsSettings}
          data={list}
          loading={loading}
          tabName={CustomerTableTabs.NEW}
          totalCount={totalCount ? totalCount : 0}
        />
      ),
    },
    {
      key: 'with_offers',
      label: (
        <Typography.Text className="order__info">
          С предложениями{' '}
          <Badge
            showZero
            className={counts?.with_offers === 0 ? 'order__badge-grey' : 'order__badge'}
            count={counts?.with_offers ? counts?.with_offers : 0}
          />
        </Typography.Text>
      ),
      children: (
        <OrdersTable
          columnsSettings={columnsSettings}
          data={list}
          loading={loading}
          tabName={CustomerTableTabs.WITH_OFFERS}
          totalCount={totalCount ? totalCount : 0}
        />
      ),
    },
    {
      key: 'with_draft_agreements',
      label: (
        <Typography.Text className="order__info">
          С подтверждениями{' '}
          <Badge
            showZero
            className={counts?.with_draft_agreements === 0 ? 'order__badge-grey' : 'order__badge'}
            count={counts?.with_draft_agreements ? counts?.with_draft_agreements : 0}
          />
        </Typography.Text>
      ),
      children: (
        <OrdersTable
          columnsSettings={columnsSettings}
          data={list}
          loading={loading}
          tabName={CustomerTableTabs.WITH_AGREEMENTS}
          totalCount={totalCount ? totalCount : 0}
        />
      ),
    },
    // {
    //   key: 'on_signing',
    //   label: (
    //     <Typography.Text className="order__info">
    //       На подписании <Badge showZero className={'order__badge-grey'} count={on_signing} />
    //     </Typography.Text>
    //   ),
    //   children: (
    //     <OrdersTable
    //       columnsSettings={columnsSettings}
    //       data={orders}
    //       loading={loading}
    //       tabName={CustomerTableTabs.AT_SIGNING}
    //     />
    //   ),
    // },
    {
      key: 'signed',
      label: (
        <Typography.Text className="order__info">
          В исполнении <Badge showZero className={'order__badge-grey'} count={counts?.signed ? counts?.signed : 0} />
        </Typography.Text>
      ),
      children: (
        <OrdersTable
          columnsSettings={columnsSettings}
          data={list}
          loading={loading}
          tabName={CustomerTableTabs.WITH_FINAL_AGREEMENT}
          totalCount={totalCount ? totalCount : 0}
        />
      ),
    },
    {
      key: 'drafts',
      label: (
        <Typography.Text className="order__info">
          Черновики <Badge showZero className={'order__badge-grey'} count={counts?.drafts ? counts?.drafts : 0} />
        </Typography.Text>
      ),
      children: (
        <OrdersTable
          columnsSettings={columnsSettings}
          data={list}
          loading={loading}
          tabName={CustomerTableTabs.DRAFT}
          totalCount={totalCount ? totalCount : 0}
        />
      ),
    },
    {
      key: 'archive',
      label: <Typography.Text className="order__info">Архив</Typography.Text>,
      children: (
        <OrdersTable
          columnsSettings={columnsSettings}
          data={list}
          loading={loading}
          tabName={CustomerTableTabs.ARCHIVED}
          totalCount={totalCount ? totalCount : 0}
        />
      ),
    },
  ];

  return tabsItems;
};
