import { Button, InputRef, Tooltip, Typography } from 'antd';
import { Contract } from '../../../../../../Models/Contract';
import { DateFilter } from '../../../../../../Components/Filters/DateFilter';
import { FilterOutlined } from '@ant-design/icons';
import { Link, useSearchParams } from 'react-router-dom';
import { NumberFilter } from '../../../../../../Components/Filters/NumberFilter';
import { ResizeCallbackData } from 'react-resizable';
import { StringFilter } from '../../../../../../Components/Filters/StringFilter';
import { initialColumns as allColumns } from '../../../../../../Store/InitialStates/OrgContractsInitialState';
import { getContractPropertyType } from '../../../../../../Utils/getContractPropertyType';
import { getUpdatedOrgColumns } from '../../../../../../Store/Slices/orgContractsColsSlice';
import {
  resetOrganizerContractsFilters,
  setOrganizerContractsFilters,
} from '../../../../../../Store/Slices/Organizer/organizerContractsSlice';
import { updateColsWidth } from '../../../../../../Utils/updateColsWidth';
import { useAppDispatch, useAppSelector } from '../../../../../../Store/hooks';
import { useEffect, useMemo, useRef, useState } from 'react';
import BlockedIcon from '../../../../../../Components/Blocked/BlockedIcon';
import type { ColumnType, ColumnsType } from 'antd/es/table';
import type { FilterConfirmProps, FilterValue } from 'antd/lib/table/interface';

export const getOrganizerContractsTableColumns = (
  columnsSettings: unknown,
  filteredInfo: Record<string, FilterValue | null>,
  setFilteredInfo: React.Dispatch<React.SetStateAction<Record<string, FilterValue | null>>>,
  showModal: (organisationId: number) => void,
) => {
  type DataIndex = keyof Contract;

  const dispatch = useAppDispatch();
  const [columns, setColumns] = useState(allColumns);
  const initialColsState = useAppSelector((state) => state.orgContractsCols);
  const [initialColumns, setInit] = useState(initialColsState);
  const [searchParams, setSearchParams] = useSearchParams();

  useMemo(() => {
    const updatedAllCols = allColumns
      .filter((allColumnsItem) => !initialColsState.find((item) => allColumnsItem['key'] === item['key']))
      .concat(initialColsState);
    setColumns(updatedAllCols);
  }, [initialColsState]);

  useEffect(() => {
    if (columnsSettings) {
      setInit(
        columns
          .filter((column) => Object.keys(columnsSettings).includes(column.key as unknown as string))
          .sort((a, b) => columnsSettings[a.key as unknown as string] - columnsSettings[b.key as unknown as string]),
      );
    }
  }, [initialColsState, columnsSettings]);

  const [searchText, setSearchText] = useState<string | number>('');
  const [searchedColumn, setSearchedColumn] = useState('');

  const handleSearch = (
    selectedKeys: string[],
    confirm: (param?: FilterConfirmProps) => void,
    dataIndex: DataIndex,
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
    dispatch(setOrganizerContractsFilters({ key: dataIndex, value: selectedKeys }));
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setFilteredInfo({});
    setSearchText('');
    setSearchedColumn('');
    dispatch(resetOrganizerContractsFilters());

    setSearchParams((params) => {
      params.set('page', '1');
      return searchParams;
    });
  };

  const searchInput = useRef<InputRef>(null);

  const getColumnSearchProps = (dataIndex: DataIndex): ColumnType<Contract> => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
      const [showSegment, setShowSegment] = useState(false);
      const [selectedFilter, setSelectedFilter] = useState('eq');
      const handleFilterChange = (operator: string) => {
        setSelectedKeys([]);
        setShowSegment(operator === 'seg');
        setSelectedFilter(operator);
      };
      return `${getContractPropertyType(dataIndex)}` === 'string' || dataIndex === 'agreementId' ? (
        <StringFilter
          searchInput={searchInput}
          selectedKeys={selectedKeys as string[]}
          setSelectedKeys={setSelectedKeys}
          handleSearch={handleSearch}
          handleReset={handleReset}
          confirm={confirm}
          dataIndex={dataIndex}
          clearFilters={clearFilters}
          setSearchText={setSearchText}
          setSearchedColumn={setSearchedColumn}
        />
      ) : `${getContractPropertyType(dataIndex)}` === 'number' ? (
        <NumberFilter
          searchInput={searchInput}
          selectedKeys={selectedKeys as string[]}
          setSelectedKeys={setSelectedKeys}
          handleSearch={handleSearch}
          handleReset={handleReset}
          handleFilterChange={handleFilterChange}
          confirm={confirm}
          dataIndex={dataIndex}
          clearFilters={clearFilters}
          setSearchText={setSearchText}
          setSearchedColumn={setSearchedColumn}
          showSegment={showSegment}
          selectedFilter={selectedFilter}
        />
      ) : `${getContractPropertyType(dataIndex)}` === 'object' ? (
        <DateFilter
          searchInput={searchInput}
          selectedKeys={selectedKeys as string[]}
          setSelectedKeys={setSelectedKeys}
          handleSearch={handleSearch}
          handleReset={handleReset}
          handleFilterChange={handleFilterChange}
          confirm={confirm}
          dataIndex={dataIndex}
          clearFilters={clearFilters}
          setSearchText={setSearchText}
          setSearchedColumn={setSearchedColumn}
          showSegment={showSegment}
          selectedFilter={selectedFilter}
        />
      ) : (
        <></>
      );
    },
    filteredValue: filteredInfo[dataIndex] || null,
    filterIcon: (filtered: boolean) => <FilterOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
  });

  const handleResize =
    (index: number) =>
    (_: React.SyntheticEvent<Element>, { size }: ResizeCallbackData) => {
      const newColumns = [...initialColumns];
      newColumns[index] = {
        ...newColumns[index],
        width: size.width,
      };
      updateColsWidth('orgContracts', newColumns, columns);
      dispatch(getUpdatedOrgColumns(newColumns));
    };

  const resizableColumns: ColumnsType<Contract> = initialColumns.map((col, index) => {
    const dataIndex = col.key! as keyof Contract;
    if (col.key! === 'agreementId') {
      return {
        ...col,
        ...getColumnSearchProps('agreementId'),
        render: (_, record) => (
          <Tooltip title={`Договор номер ${record.agreementUserFriendlyId}`} zIndex={5}>
            <Link to={`/organizer/contracts/${record.agreementId}`}>
              <Button type="default">{record.agreementUserFriendlyId}</Button>
            </Link>
          </Tooltip>
        ),
      };
    } else if (col.key! === 'organisationName') {
      return {
        ...col,
        onHeaderCell: (column) => ({
          width: (column as ColumnType<Contract>)!.width,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          onResize: handleResize(index) as React.ReactEventHandler<any>,
        }),
        ...getColumnSearchProps('organisationName'),
        render: (_, record) => (
          <Typography.Link onClick={() => showModal(record.userId)} underline>
            {record.organisationLocked && <BlockedIcon />}
            {record.organisationName}
          </Typography.Link>
        ),
      };
    }
    return {
      ...col,
      onHeaderCell: (column) => ({
        width: (column as ColumnType<Contract>)!.width,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        onResize: handleResize(index) as React.ReactEventHandler<any>,
      }),
      ...getColumnSearchProps(dataIndex),
      render: col.render,
    };
  });

  return resizableColumns;
};
