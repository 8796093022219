import { Contract } from '../Models/Contract';
import { ContractStatus } from '../Constants/Contract';
import { OrderStatus } from '../Constants/Order';

// Using to show contacts table filter depending on the type of cell`s data
export const getContractPropertyType = (propName: string) => {
  const c: Contract = {
    id: 0,
    key: 0,
    agreementId: 0,
    agreementUserFriendlyId: '',
    agreementStatus: ContractStatus.NEW,
    addressFrom: '',
    addressTo: '',
    cityFrom: '',
    cityTo: '',
    regionFrom: '',
    regionTo: '',
    cargoTypeNameWithParentName: '',
    executorOrderLink: '',
    executorOrganisationName: '',
    executorOrganisationId: 0,
    machinePrice: 0,
    comments: '',
    created: new Date(),
    arrival: new Date(),
    distance: 0,
    height: 0,
    length: 0,
    machineCategoryId: 0,
    machineName: '',
    userId: 0,
    organisationName: '',
    organisationLocked: false,
    orderId: 0,
    orderStatus: OrderStatus.NEW,
    pointFrom: '',
    pointTo: '',
    price: 0,
    specificationLink: '',
    title: '',
    weight: 0,
    width: 0,
    lastReport: new Date(),
    lastReportComment: '',
    customerId: 0,
    customerPhone: '',
    customerOrganisationId: 0,
    customerOrganisationName: '',
    customerOrganisationLocked: false,
    finalAgreementFile: [],
    orderPhotos: [],
    orderFiles: [],
    orderUserFriendlyId: '',
  };
  return typeof c[propName];
};
