import { FilterValue } from 'antd/lib/table/interface';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

const initialState = {};

export const operatorOrdersListSlice = createSlice({
  initialState: initialState,
  name: 'operatorOrdersList',
  reducers: {
    setOperatorOrdersFilters: (state, action: PayloadAction<{ key: string; value: FilterValue }>) => {
      const { key, value } = action.payload;
      state[key] = value[0];
    },
    resetOperatorOrdersFilters: () => initialState,
  },
});

export const { setOperatorOrdersFilters, resetOperatorOrdersFilters } = operatorOrdersListSlice.actions;

export default operatorOrdersListSlice.reducer;
