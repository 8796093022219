import {
  ContractCountsCustomerTableProps,
  ContractCountsOrganizerTableProps,
  IContractsTable,
} from '../../Models/Contract';
import { IFilter } from '../../Models/Table';
import { IOrderTable, OrderCountsCustomerTableProps, OrderCountsOrganizerTableProps } from '../../Models/Order';
import { IOrganizationTable, IOrganizationsCountsOperator } from '../../Models/Organizations';
import { IUsers } from '../../Models/Profile';
import { createApi } from '@reduxjs/toolkit/query/react';
import axiosBaseQuery from '../../Services/axiosBaseQuery';

export const baseAPI = createApi({
  reducerPath: 'api',
  baseQuery: axiosBaseQuery({ baseUrl: '/api/v1/' }),
  endpoints: (builder) => ({
    getCustomerContracts: builder.query<IContractsTable, IFilter>({
      query: (params) => ({
        url: 'agreement/get-list-with-report-data',
        method: 'get',
        params: { ...params.search, ...params.filter },
      }),
    }),
    getCustomerContractsCounts: builder.query<ContractCountsCustomerTableProps, void>({
      query: () => ({
        url: 'agreement/get-list-with-report-data/counts',
        method: 'get',
      }),
    }),
    getCustomerOrders: builder.query<IOrderTable, IFilter>({
      query: (params) => ({
        url: 'order/order-list',
        method: 'get',
        params: { ...params.search, ...params.filter },
      }),
    }),
    getCustomerOrdersCounts: builder.query<OrderCountsCustomerTableProps, void>({
      query: () => ({
        url: 'order/order-list/counts',
        method: 'get',
      }),
    }),
    getOrganizerOrders: builder.query<IOrderTable, IFilter>({
      query: (params) => ({
        url: 'order/list-of-associated-orders',
        method: 'get',
        params: { ...params.search, ...params.filter },
      }),
    }),
    getOrganizerOrdersCounts: builder.query<OrderCountsOrganizerTableProps, void>({
      query: () => ({
        url: 'order/list-of-associated-orders/counts',
        method: 'get',
      }),
    }),
    getOrganizerContracts: builder.query<IContractsTable, IFilter>({
      query: (params) => ({
        url: 'agreement/get-list-with-report-data',
        method: 'get',
        params: { ...params.search, ...params.filter },
      }),
    }),
    getOrganizerContractsCounts: builder.query<ContractCountsOrganizerTableProps, void>({
      query: () => ({
        url: 'agreement/get-list-with-report-data/counts',
        method: 'get',
      }),
    }),
    getOperatorOrganizations: builder.query<IOrganizationTable, IFilter>({
      query: (params) => ({
        url: 'operator/organisations',
        method: 'get',
        params: { ...params.search, ...params.filter },
      }),
    }),
    getOperatorOrganizationsCounts: builder.query<IOrganizationsCountsOperator, void>({
      query: () => ({
        url: 'operator/organisations/counts',
        method: 'get',
      }),
    }),
    getOperatorUsers: builder.query<IUsers, IFilter>({
      query: (params) => ({
        url: 'operator/profiles',
        method: 'get',
        params: { ...params.search, ...params.filter },
      }),
    }),
    getOperatorOrders: builder.query<IOrderTable, IFilter>({
      query: (params) => ({
        url: 'operator/order-list-for-operator',
        method: 'get',
        params: { ...params.search, ...params.filter },
      }),
    }),
    getOperatorContracts: builder.query<IContractsTable, IFilter>({
      query: (params) => ({
        url: 'operator/agreements-list',
        method: 'get',
        params: { ...params.search, ...params.filter },
      }),
    }),
    getAdminUsers: builder.query<IUsers, IFilter>({
      query: (params) => ({
        url: 'admin/users',
        method: 'get',
        params: { ...params.search, ...params.filter },
      }),
    }),
    getAdminOperators: builder.query<IUsers, IFilter>({
      query: (params) => ({
        url: 'admin/operators',
        method: 'get',
        params: { ...params.search, ...params.filter },
      }),
    }),
  }),
});

export const {
  useGetCustomerContractsQuery,
  useGetCustomerContractsCountsQuery,
  useGetCustomerOrdersQuery,
  useGetCustomerOrdersCountsQuery,
  useGetOrganizerOrdersQuery,
  useGetOrganizerOrdersCountsQuery,
  useGetOrganizerContractsQuery,
  useGetOrganizerContractsCountsQuery,
  useGetOperatorOrganizationsQuery,
  useGetOperatorOrganizationsCountsQuery,
  useGetOperatorUsersQuery,
  useGetOperatorOrdersQuery,
  useGetOperatorContractsQuery,
  useGetAdminUsersQuery,
  useGetAdminOperatorsQuery,
} = baseAPI;
