import { Dispatch, SetStateAction } from 'react';
import { Tabs } from 'antd';
import { useSearchParams } from 'react-router-dom';

interface ITableTabsProps {
  tab: string;
  setTab: Dispatch<SetStateAction<string>>;
  page: string | null;
  size: string | null;
  tabsItems: { key: string; label: JSX.Element; children: JSX.Element }[];
  className: string;
  url: string;
}

export const TableTabs = (props: ITableTabsProps) => {
  const { tab, setTab, page, size, tabsItems, className, url } = props;

  const [searchParams, setSearchParams] = useSearchParams();

  return (
    <div className={className}>
      <Tabs
        activeKey={tab}
        onTabClick={(key) => {
          setTab(key);
          setSearchParams((params) => {
            params.set('page', '1');
            return searchParams;
          });
          setSearchParams((params) => {
            params.set('filter', key);
            return searchParams;
          });
          window.history.replaceState(null, '', `${url}?filter=${key}&page=${page}&size=${size}`);
        }}
        items={tabsItems}
        className="tabs"
      />
    </div>
  );
};
