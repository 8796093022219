import { ColumnType, FilterConfirmProps, FilterValue } from 'antd/lib/table/interface';
import { ColumnsType } from 'antd/lib/table';
import { FilterOutlined } from '@ant-design/icons';
import { InputRef, Typography } from 'antd';
import { OrgTypes } from '../../../../../../Constants/OrgTypes';
import { Organization } from '../../../../../../Models/Organizations';
import { Roles } from '../../../../../../Constants/Profile';
import { SelectFilter } from '../../../../../../Components/Filters/SelectFilter';
import { StringFilter } from '../../../../../../Components/Filters/StringFilter';
import {
  confirmedSelectOptions,
  lockedSelectOptions,
  roleSelectOptions,
  typeSelectOptions,
} from '../../../../../../Constants/OrganizationSelectOptions';
import {
  resetOperatorOrganizationsFilters,
  setOperatorOrganizationsFilters,
} from '../../../../../../Store/Slices/Operator/operatorOrganizationsSlice';

import { useAppDispatch } from '../../../../../../Store/hooks';
import { useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

export const getOrganizationsTableColumns = (
  filteredInfo: Record<string, FilterValue | null>,
  setFilteredInfo: React.Dispatch<React.SetStateAction<Record<string, FilterValue | null>>>,
) => {
  type DataIndex = keyof Organization;

  const confirmedColValue = (record) =>
    record.role === Roles.CUSTOMER ? 'Не требуется' : record.confirmed ? 'Подтверждена' : 'Не подтверждена';
  const dispatch = useAppDispatch();

  const columns: ColumnsType<Organization> = [
    {
      title: 'Организация',
      dataIndex: 'shortName',
      sorter: true,
      key: 'shortName',
      width: 260,
      ellipsis: true,
      render: (_, record) => (
        <Typography.Link href={`/operator/organizations/${record.id}`} underline>
          {record.shortName}
        </Typography.Link>
      ),
    },
    {
      title: 'ИНН',
      dataIndex: 'inn',
      width: 116,
      ellipsis: true,
      sorter: true,
      key: 'inn',
    },
    {
      title: 'Тип организации',
      dataIndex: 'type',
      width: 185,
      ellipsis: true,
      sorter: true,
      key: 'type',
      render: (_, record) => (record.type === OrgTypes.LEGAL ? 'Юридическое лицо' : 'ИП'),
    },
    {
      title: 'Роль',
      dataIndex: 'role',
      width: 153,
      ellipsis: true,
      sorter: true,
      key: 'role',
      render: (_, record) => {
        if (record.role.includes(',')) {
          const roles = record.role.split(', ');
          return roles.map((role) => (role === Roles.DELIVERY ? 'Организатор' : 'Заказчик')).join(', ');
        } else {
          return record.role === Roles.DELIVERY ? 'Организатор' : 'Заказчик';
        }
      },
    },
    {
      title: 'Подтверждение',
      dataIndex: 'confirmed',
      width: 193,
      ellipsis: true,
      sorter: true,
      key: 'confirmed',
      render: (_, record) => confirmedColValue(record),
    },
    {
      title: 'Статус',
      dataIndex: 'locked',
      width: 185,
      ellipsis: true,
      sorter: true,
      key: 'locked',
      render: (_, record) => (record.locked ? 'Заблокирована' : 'Активна'),
    },
    {
      title: 'Эл. почта',
      dataIndex: 'email',
      width: 'auto',
      ellipsis: true,
      sorter: true,
      key: 'email',
      render: (text) => (text ? text : '—'),
    },
  ];
  const [searchText, setSearchText] = useState<string | number>('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [searchParams, setSearchParams] = useSearchParams();
  const handleSearch = (
    selectedKeys: string[],
    confirm: (param?: FilterConfirmProps) => void,
    dataIndex: DataIndex,
  ) => {
    confirm();
    setSearchText(selectedKeys.toString());
    setSearchedColumn(dataIndex);
    dispatch(setOperatorOrganizationsFilters({ key: dataIndex, value: selectedKeys }));
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setFilteredInfo({});
    setSearchText('');
    setSearchedColumn('');
    dispatch(resetOperatorOrganizationsFilters());
    setSearchParams((params) => {
      params.set('page', '1');
      return searchParams;
    });
  };

  const searchInput = useRef<InputRef>(null);
  const getColumnSearchProps = (dataIndex: DataIndex): ColumnType<Organization> => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
      const selectOptions =
        dataIndex === 'type'
          ? typeSelectOptions
          : dataIndex === 'role'
          ? roleSelectOptions
          : dataIndex === 'confirmed'
          ? confirmedSelectOptions
          : lockedSelectOptions;
      return dataIndex === 'type' || dataIndex === 'role' || dataIndex === 'confirmed' || dataIndex === 'locked' ? (
        <SelectFilter
          selectedKeys={selectedKeys as string[]}
          setSelectedKeys={setSelectedKeys}
          handleSearch={handleSearch}
          handleReset={handleReset}
          confirm={confirm}
          dataIndex={dataIndex}
          clearFilters={clearFilters}
          setSearchText={setSearchText}
          setSearchedColumn={setSearchedColumn}
          selectOptions={selectOptions}
        />
      ) : (
        <StringFilter
          searchInput={searchInput}
          selectedKeys={selectedKeys as string[]}
          setSelectedKeys={setSelectedKeys}
          handleSearch={handleSearch}
          handleReset={handleReset}
          confirm={confirm}
          dataIndex={dataIndex}
          clearFilters={clearFilters}
          setSearchText={setSearchText}
          setSearchedColumn={setSearchedColumn}
        />
      );
    },
    filteredValue: filteredInfo[dataIndex] || null,
    filterIcon: (filtered: boolean) => <FilterOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
  });

  const filterableColumns: ColumnsType<Organization> = columns.map((col, index) => {
    const dataIndex = col.key! as DataIndex;
    return {
      ...col,
      ...getColumnSearchProps(dataIndex),
      render: col.render,
    };
  });

  return filterableColumns;
};
