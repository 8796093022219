import { API } from '../../../../../Services/axios';
import { BackTop, Breadcrumb, Button, Col, Row, Tooltip, Typography } from 'antd';
import { ExportOutlined, PrinterOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { OfferStatuses } from '../../../../../Constants/OfferStatuses';
import { OrderStatus } from '../../../../../Constants/Order';
import { Roles } from '../../../../../Constants/Profile';
import { clickDownloadLink } from '../../../../../Utils/clickDownloadLink';
import { convertToMscTime } from '../../../../../Utils/convertToMscTime';
import { useAppSelector } from '../../../../../Store/hooks';
import { useErrorModal } from '../../../../../Components/Modals/ErrorModal';

interface OrderHeaderProps {
  title: string;
  orderId: number;
  orderNumber: string;
  orderStatus: OrderStatus;
  offerStatus: OfferStatuses | undefined;
  createdAt: Date;
  showCancelOrderModal?: () => void;
}

const OrderHeader = (props: OrderHeaderProps) => {
  const role = useAppSelector((state) => state.user.role);
  const isVerified = useAppSelector((state) => state.user.confirmed);
  const { title, orderId, orderStatus, orderNumber, createdAt, offerStatus, showCancelOrderModal } = props;

  const { showErrorModal } = useErrorModal();
  const isCancelAllowed =
    role === Roles.DELIVERY &&
    orderStatus !== OrderStatus.CANCELLED_BY_CUSTOMER &&
    orderStatus !== OrderStatus.CANCELLED_BY_DELIVERY &&
    orderStatus !== OrderStatus.DONE &&
    offerStatus &&
    offerStatus === OfferStatuses.ACCEPTED;

  const handleExport = () => {
    API.get(`/api/v1/order/orders-csv/?ordersIds=${orderId}`, {
      responseType: 'blob',
    })
      .then((response) => {
        const blob = response.data;
        clickDownloadLink(blob, `Заказ_${orderId}.csv`);
      })
      .catch((error) => showErrorModal({ error }));
  };
  const handlePrint = () => {
    API.get(`/api/v1/order/${orderId}/pdf/`, {
      responseType: 'blob',
    })
      .then((response) => {
        const blob = response.data;
        clickDownloadLink(blob, `Заказ_${orderId}.pdf`);
      })
      .catch((error) => showErrorModal({ error }));
  };

  return (
    <Row justify="space-between" align="middle" gutter={[0, 16]} className="order__header">
      <Col>
        <BackTop />
        <Breadcrumb className="order__breadcrumb" separator=">">
          <Breadcrumb.Item href="/">Главная страница</Breadcrumb.Item>
          <Breadcrumb.Item href="/organizer/orders">Заказы</Breadcrumb.Item>
          <Breadcrumb.Item>
            <Typography.Text ellipsis>{title}</Typography.Text>
          </Breadcrumb.Item>
        </Breadcrumb>
      </Col>
      <Col>
        <Typography.Text className="order__info">Номер заказа:</Typography.Text>
        <Typography.Text className="order__info" strong>
          {orderNumber}
        </Typography.Text>
        <Tooltip className="order__header-icon" title="Номер заказа нужен для обращения в поддержку">
          <QuestionCircleOutlined />
        </Tooltip>
        <Typography.Text className="order__info">Создан:</Typography.Text>
        <Typography.Text className="order__info" strong>
          {`${convertToMscTime(createdAt)} мск`}
        </Typography.Text>
      </Col>
      <Col span={18}>
        <Typography.Title level={2} ellipsis={{ tooltip: false }}>
          {title}
        </Typography.Title>
      </Col>
      <Col span="200px">
        <Button
          size="large"
          icon={<ExportOutlined />}
          style={{ marginRight: 10 }}
          disabled={!isVerified}
          onClick={handleExport}
        >
          Экспорт
        </Button>
        <Button size="large" icon={<PrinterOutlined />} disabled={!isVerified} onClick={handlePrint}>
          Печать
        </Button>
      </Col>
      {isCancelAllowed && (
        <Col span="250px">
          <Button size="large" onClick={showCancelOrderModal}>
            Договор не может быть подписан
          </Button>
        </Col>
      )}
    </Row>
  );
};

export default OrderHeader;
