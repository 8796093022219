import { Button, Input, InputRef, Space } from 'antd';
import { ColumnType, FilterConfirmProps, FilterValue } from 'antd/lib/table/interface';
import { ColumnsType } from 'antd/lib/table';
import { DeleteOutlined, EditOutlined, FilterOutlined } from '@ant-design/icons';
import { Rate } from '../../../../../../../Models/Rate';
import { formatPrice } from '../../../../../../../Utils/formatPrice';
import { useRef, useState } from 'react';

export const getRatesTableColumns = (
  filteredInfo: Record<string, FilterValue | null>,
  setFilteredInfo: React.Dispatch<React.SetStateAction<Record<string, FilterValue | null>>>,
  setClickedRate: React.Dispatch<React.SetStateAction<Rate | undefined>>,
  setOpenEditRateModal: React.Dispatch<React.SetStateAction<boolean>>,
  setOpenDeleteRateModal: React.Dispatch<React.SetStateAction<boolean>>,
) => {
  const columns: ColumnsType<Rate> = [
    {
      title: 'Регион отправки',
      dataIndex: 'displayNameFrom',
      sorter: (a, b) => a.displayNameFrom.localeCompare(b.displayNameFrom),
      key: 'displayNameFrom',
      width: 400,
      render: (_, record) => (record.cityFrom ? `${record.regionFrom}, ${record.cityFrom}` : `${record.regionFrom}`),
    },
    {
      title: 'Регион доставки',
      dataIndex: 'displayNameTo',
      width: 400,
      sorter: (a, b) => a.displayNameTo.localeCompare(b.displayNameTo),
      key: 'displayNameTo',
      render: (_, record) => (record.cityTo ? `${record.regionTo}, ${record.cityTo}` : `${record.regionTo}`),
    },
    {
      title: 'Тариф за 1км',
      dataIndex: 'price',
      width: 392,
      render: (_, record) => `${formatPrice(+record.price)} ₽`,
      sorter: (a, b) => a.price - b.price,
      key: 'price',
    },
    {
      title: 'Действия',
      key: 'actions',
      width: 120,
      render: (_, record) => (
        <div>
          <Button icon={<EditOutlined />} size="large" type="text" onClick={() => handleEdit(record)} />
          <Button danger icon={<DeleteOutlined />} size="large" type="text" onClick={() => handleDelete(record)} />
        </div>
      ),
    },
  ];

  const handleEdit = (rate: Rate) => {
    setClickedRate(rate);
    setOpenEditRateModal(true);
  };

  const handleDelete = (rate: Rate) => {
    setClickedRate(rate);
    setOpenDeleteRateModal(true);
  };

  const [searchText, setSearchText] = useState<string | number>('');
  const [searchedColumn, setSearchedColumn] = useState('');

  const handleSearch = (
    selectedKeys: string[],
    confirm: (param?: FilterConfirmProps) => void,
    dataIndex: keyof Rate,
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setFilteredInfo({});
    setSearchText('');
    setSearchedColumn('');
  };

  const searchInput = useRef<InputRef>(null);
  const getColumnSearchProps = (dataIndex: keyof Rate): ColumnType<Rate> => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Начните вводить для поиска`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button onClick={() => clearFilters && handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Сброс
          </Button>
          <Button
            size="small"
            type="primary"
            icon={<FilterOutlined />}
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText((selectedKeys as string[])[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Фильтровать
          </Button>
        </Space>
      </div>
    ),
    filteredValue: filteredInfo[dataIndex] || null,
    filterIcon: (filtered: boolean) => <FilterOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) => {
      const cellValue = record[dataIndex] ?? '—';
      return cellValue
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase());
    },
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
  });

  const filterableColumns: ColumnsType<Rate> = columns.map((col, index) => {
    const dataIndex = col.key! as keyof Rate;
    if (col.key! === 'actions') {
      return {
        ...col,
      };
    }
    return {
      ...col,
      onHeaderCell: (column) => ({
        width: (column as ColumnType<Rate>)!.width,
      }),
      ...getColumnSearchProps(dataIndex),
      render: col.render,
    };
  });

  return filterableColumns;
};
