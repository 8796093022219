import { API } from '../../Services/axios';
import { Button, Modal, Tooltip, Tree } from 'antd';
import { ColumnGroupType, ColumnType } from 'rc-table/lib/interface';
import { Contract } from '../../Models/Contract';
import { Key } from 'rc-tree/lib/interface';
import { Link } from 'react-router-dom';
import { Order } from '../../Models/Order';
import { Roles } from '../../Constants/Profile';
import { chain } from 'lodash';
import { customerContractColumnsList, orgContractColumnsList } from '../../Constants/ContractsColumnsList';
import { initialColumns as customerContractsInitialColumns } from '../../Store/InitialStates/CustomerContractsInitialState';
import { initialColumns as customerInitialOrderColumns } from '../../Store/InitialStates/CustomerOrdersInitialState';
import { customerOrderColumnsList, orgOrderColumnsList } from '../../Constants/OrdersColumnsList';
import { getUpdatedCustomerColumns } from '../../Store/Slices/customerOrdersColsSlice';
import { getUpdatedCustomerColumns as getUpdatedCustomerContractsColumns } from '../../Store/Slices/customerContractsColsSlice';
import { getUpdatedOrgColumns } from '../../Store/Slices/orgOrdersColsSlice';
import { getUpdatedOrgColumns as getUpdatedOrgContractsColumns } from '../../Store/Slices/orgContractsColsSlice';
import { onDropAtnTree } from '../../Utils/dragAndDropAntTree';
import { initialColumns as orgContractsInitialColumns } from '../../Store/InitialStates/OrgContractsInitialState';
import { initialColumns as orgInitialOrderColumns } from '../../Store/InitialStates/OrgOrdersInitialState';
import { useAppDispatch, useAppSelector } from '../../Store/hooks';
import { useEffect, useState } from 'react';
import { useErrorModal } from './ErrorModal';
import ModalFooter from './ModalFooter';
import ModalTitle from './ModalTitle';
import type { TreeProps } from 'antd/es/tree';

interface TableSettingsModalProps {
  open: boolean;
  type: string;
  columnsSettings: unknown;
  setColumnsSettings: React.Dispatch<React.SetStateAction<unknown>>;
  handleCancel: () => void;
}

type ColKeyType =
  | {
      checked: Key[];
      halfChecked: Key[];
    }
  | Key[];

type InitialColsType = ColumnGroupType<Order> | ColumnType<Order> | ColumnGroupType<Contract> | ColumnType<Contract>;

const TableSettingsModal = (props: TableSettingsModalProps) => {
  const { showErrorModal } = useErrorModal();
  const { open, type, columnsSettings, setColumnsSettings, handleCancel } = props;
  const dispatch = useAppDispatch();
  const role = useAppSelector((state) => state.user.role);
  const [isLoading, setLoading] = useState(false);

  const isCustomer = role === Roles.CUSTOMER;

  const initialColumns =
    type === 'ORDER'
      ? isCustomer
        ? customerInitialOrderColumns
        : orgInitialOrderColumns
      : isCustomer
      ? customerContractsInitialColumns
      : orgContractsInitialColumns;

  const [columns, setColumns] = useState(initialColumns);

  const presetColumns = useAppSelector((state) =>
    type === 'ORDER'
      ? isCustomer
        ? state.customerOrdersCols
        : state.orgOrdersCols
      : isCustomer
      ? state.customerContractsCols
      : state.orgContractsCols,
  );

  useEffect(() => {
    let updatedAllCols = initialColumns;
    type === 'ORDER'
      ? isCustomer
        ? (updatedAllCols = customerInitialOrderColumns
            .filter((initCalsItem) => !presetColumns.find((item) => initCalsItem['key'] === item['key']))
            .concat(presetColumns))
        : (updatedAllCols = orgInitialOrderColumns
            .filter((initCalsItem) => !presetColumns.find((item) => initCalsItem['key'] === item['key']))
            .concat(presetColumns))
      : isCustomer
      ? (updatedAllCols = customerContractsInitialColumns
          .filter((initCalsItem) => !presetColumns.find((item) => initCalsItem['key'] === item['key']))
          .concat(presetColumns))
      : (updatedAllCols = orgContractsInitialColumns
          .filter((initCalsItem) => !presetColumns.find((item) => initCalsItem['key'] === item['key']))
          .concat(presetColumns));

    setColumns(updatedAllCols);
  }, [open]);

  const initialColumnsKeysList = columns
    .map((col) => col.key!)
    .filter((key) => (type === 'ORDER' ? key !== 'userFriendlyId' : key !== 'agreementId'));

  let defaultChecked = presetColumns
    .map((col) => col.key!)
    .filter((key) => (type === 'ORDER' ? key !== 'userFriendlyId' : key !== 'agreementId'));
  defaultChecked = columnsSettings
    ? initialColumnsKeysList.filter((key) => Object.keys(columnsSettings).includes(key as unknown as string))
    : defaultChecked;

  const [chekedCols, setChekedCols] = useState<ColKeyType>(defaultChecked);
  const [gData, setGData] = useState(
    type === 'ORDER'
      ? isCustomer
        ? customerOrderColumnsList
        : orgOrderColumnsList
      : isCustomer
      ? customerContractColumnsList
      : orgContractColumnsList,
  );
  const [resCols, setResCols] = useState(columns);

  const onCheck: TreeProps['onCheck'] = (checkedKeys) => {
    setChekedCols(() => checkedKeys);
  };

  useEffect(() => {
    if (columnsSettings) {
      setGData(
        gData.sort((a, b) => columnsSettings[a.key as unknown as string] - columnsSettings[b.key as unknown as string]),
      );
    }
  }, [columnsSettings]);

  useEffect(() => {
    const listKeys = gData.map((item) => item.key);

    const selectedColumns = chain(chekedCols)
      .map((key) => (columns as InitialColsType[]).find((col) => col.key === key))
      .value();

    const sortedColumns = chain(listKeys)
      .map((key) => selectedColumns.find((col) => col!.key === key))
      .filter((col) => col !== undefined)
      .value();

    const resultColumns =
      type === 'ORDER'
        ? [
            {
              key: 'userFriendlyId',
              title: '№',
              dataIndex: ['userFriendlyId', 'id'],
              fixed: 'left' as const,
              width: 120,
              sorter: true,
              render: (_, record) => (
                <Tooltip title={`Заказ номер ${record.userFriendlyId}`} zIndex={5}>
                  {/* {record.status === 'DRAFT' ? (
                    <Link to={`/customer/draft/${record.id}`}>
                      <Button type="default">{record.userFriendlyId}</Button>
                    </Link>
                  ) : ( */}
                  <Link to={`/${isCustomer ? 'customer' : 'organizer'}/orders/${record.id}`}>
                    <Button type="default">{record.userFriendlyId}</Button>
                  </Link>
                  {/* )} */}
                </Tooltip>
              ),
            },
            ...(sortedColumns as (ColumnGroupType<Order> | ColumnType<Order>)[]),
          ]
        : [
            {
              key: 'agreementId',
              title: '№',
              dataIndex: 'agreementId',
              fixed: 'left' as const,
              width: 120,
              sorter: true,
              render: (_, record) => (
                <Tooltip title={`Договор номер ${record.agreementUserFriendlyId}`} zIndex={5}>
                  <Link to={`/${isCustomer ? 'customer' : 'organizer'}/contracts/${record.agreementId}`}>
                    <Button type="default">{record.agreementUserFriendlyId}</Button>
                  </Link>
                </Tooltip>
              ),
            },
            ...(sortedColumns as (ColumnGroupType<Contract> | ColumnType<Contract>)[]),
          ];
    setResCols(resultColumns);
  }, [gData, chekedCols]);

  const handleConfirm = () => {
    type === 'ORDER'
      ? isCustomer
        ? dispatch(getUpdatedCustomerColumns(resCols))
        : dispatch(getUpdatedOrgColumns(resCols))
      : isCustomer
      ? dispatch(getUpdatedCustomerContractsColumns(resCols))
      : dispatch(getUpdatedOrgContractsColumns(resCols));

    const selectedOrderedColumns = { tableType: type === 'ORDER' ? 'order' : 'agreement' };
    resCols.forEach((col, index) => (selectedOrderedColumns[col.key] = index));
    const headers = { headers: { 'Content-Type': 'application/json' } };

    setColumnsSettings(selectedOrderedColumns);

    setLoading(true);

    API.post(`/api/v1/table-settings`, JSON.stringify(selectedOrderedColumns), headers)
      .catch((error) => showErrorModal({ error }))
      .finally(() => {
        handleCancel();
        setLoading(false);
      });
  };

  return (
    <Modal
      title={<ModalTitle level={3} title="Настройка колонок таблицы" handleCancel={handleCancel} />}
      open={open}
      onCancel={handleCancel}
      className="modal modal__info modal__table-settings"
      centered
      width={645}
      closable={false}
      footer={
        <ModalFooter
          okText="Применить"
          cancelText="Отменить"
          handleConfirm={handleConfirm}
          handleCancel={handleCancel}
          disabled={isLoading}
        />
      }
      bodyStyle={{ overflowX: 'auto', maxHeight: 'calc(100vh - 200px)' }}
    >
      <Tree
        className="draggable-tree"
        checkable
        draggable
        blockNode
        treeData={gData}
        defaultCheckedKeys={defaultChecked}
        onCheck={onCheck}
        onDrop={onDropAtnTree(setGData, gData)}
      />
    </Modal>
  );
};

export default TableSettingsModal;
