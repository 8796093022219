import { Alert, Typography } from 'antd';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { OfferStatuses } from '../../../../../Constants/OfferStatuses';
import { OrderStatus } from '../../../../../Constants/Order';

interface OfferMessageProps {
  status?: OfferStatuses;
  orderStatus: OrderStatus;
  orderTakenByAnotherOrg: boolean;
  contractId: number | undefined;
  attempt: number;
}

const OfferMessage = (props: OfferMessageProps) => {
  const { status, orderStatus, orderTakenByAnotherOrg, contractId, attempt } = props;

  if (
    attempt >= 3 &&
    (status === OfferStatuses.CANCELLED ||
      status === OfferStatuses.CANCELLED_BY_DELIVERY ||
      status === OfferStatuses.CANCELLED_BY_CUSTOMER)
  ) {
    return (
      <Alert
        message={
          <Typography.Text className="offer__info">
            Вы больше не можете отправить предложение для этого заказа, потому что израсходовали лимит предложений на
            один заказ
          </Typography.Text>
        }
        type="error"
        showIcon
        icon={<ExclamationCircleFilled />}
      />
    );
  }
  if (orderStatus === OrderStatus.CANCELLED_BY_CUSTOMER || orderTakenByAnotherOrg) {
    return (
      <Alert
        message={<Typography.Text className="offer__info">Этот заказ больше не доступен в системе</Typography.Text>}
        type="error"
        showIcon
        icon={<ExclamationCircleFilled />}
      />
    );
  }
  if (orderStatus === OrderStatus.CANCELLED_BY_DELIVERY && !contractId) {
    return (
      <Alert
        message={
          <Typography.Text className="offer__info">Вы отменили подписание договора для этого заказа</Typography.Text>
        }
        type="warning"
        showIcon
        icon={<ExclamationCircleFilled />}
      />
    );
  }
  if (status === OfferStatuses.CANCELLED_BY_CUSTOMER || status === OfferStatuses.CANCELLED) {
    return (
      <Alert
        message={
          <Typography.Text className="offer__info">
            Ваше предложение было отклонено заказчиком, вы можете отправить новое предложение.
          </Typography.Text>
        }
        type="warning"
        showIcon
      />
    );
  }
  if (status === OfferStatuses.CANCELLED_BY_DELIVERY) {
    return (
      <Alert
        message={
          <Typography.Text className="offer__info">
            Вы отозвали предложение, сделанное по этому заказу, вы можете отправить новое предложение
          </Typography.Text>
        }
        type="warning"
        showIcon
      />
    );
  }
  if (status === OfferStatuses.OUT_OF_VALIDITY_DATE) {
    return (
      <Alert
        message={
          <Typography.Text className="offer__info">
            Ваше предложение отменено, потому что истек срок его актуальности
          </Typography.Text>
        }
        type="warning"
        showIcon
      />
    );
  }

  return <></>;
};

export default OfferMessage;
