import { API } from '../../../../Services/axios';
import { ContractTableOrganizerProps } from '../../../../Models/Contract';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Tabs } from 'antd';
import { getContractsTabsItems } from './Component/tabs-items';
import { useAppSelector } from '../../../../Store/hooks';
import { useErrorModal } from '../../../../Components/Modals/ErrorModal';
import { useGetOrganizerContractsQuery } from '../../../../Store/Slices/api';
import { useSearchParams } from 'react-router-dom';
import Toolbar from './Toolbar';
import UnverifiedOrgAlert from '../../Order/Detail/Component/unverified-alert';

interface CustomerContractsProps extends ContractTableOrganizerProps {
  setShowActions: React.Dispatch<React.SetStateAction<boolean>>;
  selectedRowKeys: React.Key[];
  setSelectedRowKeys: React.Dispatch<React.SetStateAction<React.Key[]>>;
  columnsSettings: unknown;
  setTab: Dispatch<SetStateAction<string>>;
  loading: boolean;
}

const Contracts = () => {
  const { showErrorModal } = useErrorModal();
  const [showActions, setShowActions] = useState(false);
  const isVerified = useAppSelector((state) => state.user.confirmed);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [loading, setLoading] = useState(false);

  const [columnsSettings, setColumnsSettings] = useState<unknown>(null);
  const [searchParams, setSearchParams] = useSearchParams();

  const [tab, setTab] = useState(searchParams.get('filter')! !== '0' ? searchParams.get('filter')! : 'all');
  const page = searchParams.get('page') ? searchParams.get('page') : '1';
  const size = searchParams.get('size') ? searchParams.get('size') : localStorage.getItem('sizePage');
  const sort = searchParams.get('sort') ? searchParams.get('sort') : 'descend';
  const sortField = searchParams.get('sortField') ? searchParams.get('sortField') : 'agreementId';

  const params = { filter: {}, search: {} };
  const paramsSearch = useAppSelector((state) => state.organizerContractsList);
  params.search = paramsSearch;

  searchParams.forEach((value, key) => {
    params.filter[key] = value;
  });

  const { data, isLoading, error } = useGetOrganizerContractsQuery(params);
  if (error) {
    showErrorModal(error);
  }
  useEffect(() => {
    setLoading(isLoading);
  }, [isLoading]);

  useEffect(() => {
    setLoading(true);
    API.get(`/api/v1/table-settings/agreement`).then((response) => {
      setColumnsSettings(response.data);
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    if (size) {
      localStorage.setItem('sizePage', size);
    }
    if (page && size && tab) {
      setSearchParams((params) => {
        params.set('page', page);
        return searchParams;
      });
      setSearchParams((params) => {
        params.set('size', size);
        return searchParams;
      });
      setSearchParams((params) => {
        params.set('filter', tab);
        return searchParams;
      });
    }
    if (sort && sortField) {
      setSearchParams((params) => {
        params.set('sort', sort);
        return searchParams;
      });
      setSearchParams((params) => {
        params.set('sortField', sortField);
        return searchParams;
      });
    }
  }, [tab, size, page, sort, sortField]);

  const contractsProps: CustomerContractsProps = {
    list: data?.list ? data?.list : [],
    totalCount: data?.totalCount ? data?.totalCount : 0,
    setShowActions: setShowActions,
    selectedRowKeys: selectedRowKeys,
    setSelectedRowKeys: setSelectedRowKeys,
    loading: loading,
    columnsSettings: columnsSettings,
    setTab: setTab,
  };

  const tabsItems = getContractsTabsItems(contractsProps);

  return (
    <div>
      {!isVerified && (
        <div className="profile__unverified">
          <UnverifiedOrgAlert />
        </div>
      )}
      <Toolbar
        selectedRowKeys={selectedRowKeys}
        showActions={showActions}
        columnsSettings={columnsSettings}
        setColumnsSettings={setColumnsSettings}
      />
      <div className="contracts__tabs-table">
        <Tabs
          activeKey={tab}
          onTabClick={(key) => {
            setTab(key);
            setShowActions(false);
            setSelectedRowKeys([]);
            setSearchParams((params) => {
              params.set('page', '1');
              return searchParams;
            });
            window.history.replaceState(null, '', `/organizer/contracts?filter=${key}&page=${page}&size=${size}`);
          }}
          items={tabsItems}
          className="tabs"
        />
      </div>
    </div>
  );
};

export default Contracts;
