import { FilterValue } from 'antd/lib/table/interface';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

const initialState = {};

export const adminOperatorsListSlice = createSlice({
  initialState: initialState,
  name: 'adminOperatorsList',
  reducers: {
    setAdminOperatorsFilters: (state, action: PayloadAction<{ key: string; value: FilterValue }>) => {
      const { key, value } = action.payload;
      state[key] = value[0];
    },
    resetAdminOperatorsFilters: () => initialState,
  },
});

export const { setAdminOperatorsFilters, resetAdminOperatorsFilters } = adminOperatorsListSlice.actions;

export default adminOperatorsListSlice.reducer;
