export const getFilterData = (paginationInfo, sortInfo, searchParams, setSearchParams) => {
  if (paginationInfo?.current) {
    setSearchParams((params) => {
      params.set('page', paginationInfo.current ? paginationInfo.current.toString() : '');
      return searchParams;
    });
  }
  if (paginationInfo?.pageSize) {
    setSearchParams((params) => {
      params.set('size', paginationInfo.pageSize ? paginationInfo.pageSize.toString() : '');
      return searchParams;
    });
  }
  if (sortInfo?.sortOrder) {
    setSearchParams((params) => {
      params.set('sort', sortInfo.sortOrder ? sortInfo.sortOrder.toString() : '');
      return searchParams;
    });
  }
  if (sortInfo?.sortField) {
    setSearchParams((params) => {
      params.set('sortField', sortInfo.sortField ? sortInfo.sortField.toString() : '');
      return searchParams;
    });
  }
};
