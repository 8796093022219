import { OfferStatuses } from '../../../../Constants/OfferStatuses';
import { OfferType } from '../../../../Models/Offer';
import { OrderStatus } from '../../../../Constants/Order';
import { useParams } from 'react-router';
import OfferForm from './Offer/OfferForm';
import OfferInformation from './Offer/OfferInformation';
import OfferMessage from './Offer/OfferMessage';

interface OfferCardProps {
  offerData: OfferType | undefined;
  contractId: number | undefined;
  externalCustomerId?: number;
  orderStatus: OrderStatus;
}

const OfferCard = (props: OfferCardProps) => {
  const { offerData, orderStatus, contractId, externalCustomerId } = props;
  const { id } = useParams();

  const isOfferCancelled =
    offerData?.status === OfferStatuses.CANCELLED ||
    offerData?.status === OfferStatuses.CANCELLED_BY_CUSTOMER ||
    offerData?.status === OfferStatuses.CANCELLED_BY_DELIVERY ||
    offerData?.status === OfferStatuses.OUT_OF_VALIDITY_DATE;

  const isOrderCancelled =
    orderStatus === OrderStatus.CANCELLED_BY_CUSTOMER || orderStatus === OrderStatus.CANCELLED_BY_DELIVERY;

  const offerExists = !!offerData;

  const orderTakenByAnotherOrg =
    (orderStatus === OrderStatus.AGREEMENT_ACCEPTED ||
      orderStatus === OrderStatus.IN_PROGRESS ||
      orderStatus === OrderStatus.DONE) &&
    !offerExists;

  const currentAttempt = offerData ? offerData.attempt : 1;

  return (
    <>
      <OfferMessage
        status={offerData?.status}
        orderStatus={orderStatus}
        orderTakenByAnotherOrg={orderTakenByAnotherOrg}
        contractId={contractId}
        attempt={currentAttempt}
      />

      <br></br>

      {!isOrderCancelled &&
        !orderTakenByAnotherOrg &&
        (!isOfferCancelled || currentAttempt < 3) &&
        (offerExists && !isOfferCancelled ? (
          <OfferInformation offerData={offerData} isOfferCancelled={isOfferCancelled} />
        ) : (
          <OfferForm orderId={+id!} externalCustomerId={externalCustomerId} />
        ))}
    </>
  );
};

export default OfferCard;
