import { FilterValue } from 'antd/lib/table/interface';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

const initialState = {};

export const operatorUsersListSlice = createSlice({
  initialState: initialState,
  name: 'operatorUsersList',
  reducers: {
    setOperatorUsersFilters: (state, action: PayloadAction<{ key: string; value: FilterValue }>) => {
      const { key, value } = action.payload;
      state[key] = value[0];
    },
    resetOperatorUsersFilters: () => initialState,
  },
});

export const { setOperatorUsersFilters, resetOperatorUsersFilters } = operatorUsersListSlice.actions;

export default operatorUsersListSlice.reducer;
