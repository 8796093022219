import { Col, Row, Tabs, Typography } from 'antd';
import { OrganizationsProps } from '../../../../Models/Organizations';
import { getOrganizationsTabsItems } from './Component/tab-items';
import { useAppSelector } from '../../../../Store/hooks';
import { useEffect, useState } from 'react';
import { useErrorModal } from '../../../../Components/Modals/ErrorModal';
import { useGetOperatorOrganizationsQuery } from '../../../../Store/Slices/api';
import { useSearchParams } from 'react-router-dom';

const Organizations = () => {
  const { showErrorModal } = useErrorModal();
  const [loading, setLoading] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();
  const [tab, setTab] = useState(searchParams.get('filter') ?? 'all');
  const page = searchParams.get('page') ? searchParams.get('page') : '1';
  const size = searchParams.get('size') ? searchParams.get('size') : localStorage.getItem('sizePage');
  const sort = searchParams.get('sort') ? searchParams.get('sort') : 'descend';
  const sortField = searchParams.get('sortField') ? searchParams.get('sortField') : 'createdAt';

  const params = { filter: {}, search: {} };
  const paramsSearch = useAppSelector((state) => state.operatorOrganisationList);
  params.search = paramsSearch;

  searchParams.forEach((value, key) => {
    params.filter[key] = value;
  });

  const { data, isLoading, error } = useGetOperatorOrganizationsQuery(params);
  if (error) {
    showErrorModal(error);
  }

  useEffect(() => {
    setLoading(isLoading);
  }, [isLoading]);

  useEffect(() => {
    if (size) {
      localStorage.setItem('sizePage', size);
    }
    if (page && size && tab) {
      setSearchParams((params) => {
        params.set('page', page);
        return searchParams;
      });
      setSearchParams((params) => {
        params.set('size', size);
        return searchParams;
      });
      setSearchParams((params) => {
        params.set('filter', tab);
        return searchParams;
      });
    }
    if (sort && sortField) {
      setSearchParams((params) => {
        params.set('sort', sort);
        return searchParams;
      });
      setSearchParams((params) => {
        params.set('sortField', sortField);
        return searchParams;
      });
    }
  }, [tab, page, size, sort, sortField]);

  const organizationsProps: OrganizationsProps = {
    list: data?.list ? data?.list : [],
    loading: loading,
    totalCount: data?.totalCount ? data?.totalCount : 0,
  };

  const tabsItems = getOrganizationsTabsItems(organizationsProps);

  return (
    <div>
      <Row className="toolbar__title" justify="space-between">
        <Col>
          <Typography.Title level={2}>Организации</Typography.Title>
        </Col>
      </Row>
      <div className="organizations__tabs-table">
        <Tabs
          activeKey={`${tab}`}
          items={tabsItems}
          onTabClick={(key) => {
            setTab(key);
            setSearchParams((params) => {
              params.set('page', '1');
              return searchParams;
            });
            window.history.replaceState(
              null,
              '',
              `/operator/organizations?filter=${key}&page=${page}&size=${size}&sort=${sort}&sortField=${sortField}`,
            );
          }}
          className="tabs"
        />
      </div>
    </div>
  );
};

export default Organizations;
