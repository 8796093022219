import 'dayjs/locale/ru';
import { API } from '../../../Services/axios';
import { Button, Col, ConfigProvider, Divider, Row, Space, Typography } from 'antd';
import { Dates } from './AnalyticalReport';
import { Dispatch, SetStateAction, useState } from 'react';
import { ExportOutlined } from '@ant-design/icons';
import { clickDownloadLink } from '../../../Utils/clickDownloadLink';
import { defaultDateFormat } from '../../../Constants/DateFormats';
import { disableFutureDates } from '../../../Utils/disableFutureDates';
import { useErrorModal } from '../../../Components/Modals/ErrorModal';
import DatePicker from '../../../Components/DatePicker/DatePicker';
import dayjs from 'dayjs';
import locale from 'antd/es/locale/ru_RU';
dayjs().locale('ru');

const { RangePicker } = DatePicker;

interface ToolbarProps extends Dates {
  dates: Dates;
  setDates: Dispatch<SetStateAction<Dates>>;
}

const Toolbar = (props: ToolbarProps) => {
  const { dateFrom, dateTo, setDates, dates } = props;
  const { showErrorModal } = useErrorModal();
  const [newDates, setNewDates] = useState<Dates>({ dateFrom, dateTo });
  const [disabledBtn, setDisabledBtn] = useState<boolean>(false);

  const stringDateFrom = dates.dateFrom.toISOString();
  const stringDateTo = dates.dateTo.toISOString();

  const onChange = (values) => {
    if (values) {
      const period = {
        dateFrom: values[0],
        dateTo: values[1],
      };

      setNewDates(period);
      setDisabledBtn(false);
    } else {
      setDisabledBtn(true);
    }
  };

  const handleConfirm = () => {
    setDates(newDates);
  };

  const handleClick = () => {
    API.get(`/api/v1/admin/statistics/orders/${stringDateFrom}/${stringDateTo}`, {
      responseType: 'blob',
    })
      .then((response) => {
        const blob = response.data;
        clickDownloadLink(blob, `Аналитика_за_${stringDateFrom}-${stringDateTo}.csv`);
      })
      .catch((error) => showErrorModal({ error }));
  };

  return (
    <div>
      <Row className="toolbar__title" justify="space-between" gutter={[0, 32]} align="bottom">
        <Col span={24}>
          <Typography.Title level={2}>Аналитический отчет</Typography.Title>
        </Col>
        <Col span={24}>
          <Divider />
        </Col>
        <Col>
          <Space direction="vertical" size={6}>
            <Typography.Text strong type="secondary">
              Отчетный период
            </Typography.Text>
            <ConfigProvider locale={locale}>
              <RangePicker
                size="large"
                defaultValue={[dateFrom, dateTo]}
                format={defaultDateFormat}
                onChange={onChange}
                disabledDate={(current) => disableFutureDates(current)}
              />
            </ConfigProvider>
          </Space>
        </Col>
        <Col className="toolbar__btns">
          <Button size={'large'} type="primary" onClick={handleConfirm} disabled={disabledBtn}>
            Сформировать отчет
          </Button>
        </Col>
        <Col className="toolbar__btns">
          <Button size={'large'} type="default" icon={<ExportOutlined />} onClick={handleClick} disabled={disabledBtn}>
            Экспорт
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default Toolbar;
