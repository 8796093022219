import { API } from '../../../../../../Services/axios';
import { Button, Col, Row, Space, Typography } from 'antd';
import { clickDownloadLink } from '../../../../../../Utils/clickDownloadLink';
import { useErrorModal } from '../../../../../../Components/Modals/ErrorModal';

const Toolbar = () => {
  const { showErrorModal } = useErrorModal();
  const handleClick = () => {
    API.get(`/api/v1/admin/emails-list/`, {
      responseType: 'blob',
    })
      .then((response) => {
        const blob = response.data;
        clickDownloadLink(blob, `Список_e-mail_пользователей.csv`);
      })
      .catch((error) => showErrorModal({ error }));
  };

  return (
    <Row className="toolbar__admin-title" justify="end">
      <Col className="toolbar__admin-btns">
        <Space size={18}>
          <Typography.Text>
            Выгрузить список e-mail пользователей, согласных на информационные сообщения
          </Typography.Text>
          <Button size={'large'} type="primary" onClick={handleClick}>
            Выгрузить
          </Button>
        </Space>
      </Col>
    </Row>
  );
};

export default Toolbar;
