import { API } from '../../Services/axios';
import { Card, Divider, Space, Typography } from 'antd';
import { Dispatch, SetStateAction } from 'react';
import { LogoutOutlined } from '@ant-design/icons';
import { NavigateOptions, URLSearchParamsInit } from 'react-router-dom';
import { Roles } from '../../Constants/Profile';
import { getUserInfo } from '../../Store/Slices/userSlice';
import { landingUrl } from '../../Constants/MenuItems';
import { profileItems } from '../../Constants/ProfileItems';
import { useAppDispatch, useAppSelector } from '../../Store/hooks';

interface ProfileMenuProps {
  current: number;
  setCurrent: Dispatch<SetStateAction<number>>;
  setSearchParams: (
    nextInit?: URLSearchParamsInit | ((prev: URLSearchParams) => URLSearchParamsInit),
    navigateOpts?: NavigateOptions,
  ) => void;
}

const ProfileMenu = (props: ProfileMenuProps) => {
  const { current, setCurrent, setSearchParams } = props;
  const dispatch = useAppDispatch();
  const role = useAppSelector((state) => state.user.role);

  const onChangeTab = (current) => {
    setCurrent(+current);
    setSearchParams({ tab: current });
    window.history.replaceState(null, '', `/profile?tab=${current}`);
  };
  const logout = () => {
    API.get('/api/v1/logout').then(() => window.location.replace(landingUrl));
    dispatch(
      getUserInfo({
        id: null,
        role: null,
        name: null,
        avatarLink: null,
        confirmed: null,
        fioPdf: null,
        organisationId: null,
      }),
    );
  };

  return (
    <Card>
      <Space direction="vertical" size={32} style={{ width: '100%' }}>
        {profileItems.map((item) => {
          if ((item.key > 3 && role === Roles.DELIVERY) || item.key <= 3 || item.key === 6)
            return (
              <Space
                key={item.key}
                size={16}
                onClick={() => onChangeTab(item.key)}
                className={current === item.key ? 'profile__menu_item profile__menu_item-active' : 'profile__menu_item'}
              >
                {item.icon}
                <Typography.Text>{item.title}</Typography.Text>
              </Space>
            );
        })}
        <Divider />
        <Space size={16} onClick={logout} className="profile__menu_item">
          <LogoutOutlined />
          <Typography.Text>Выйти</Typography.Text>
        </Space>
      </Space>
    </Card>
  );
};

export default ProfileMenu;
