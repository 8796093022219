import { ContractsTableProps } from '../../../../../Models/Contract';
import { OrgTableTabs } from './Component/empty-message';
import { ResizableTitle } from './Component/resizable-title';
import { Table } from 'antd';
import { getOrganizerContractsTableColumns } from './Component/columns';
import { getOrganizerContractsTableFilter } from './Component/filters';

import { FilterValue } from 'antd/lib/table/interface';
import { getFilterData } from '../../../../../Utils/getFilterData';
import {
  resetOrganizerContractsFilters,
  setOrganizerContractsFilters,
} from '../../../../../Store/Slices/Organizer/organizerContractsSlice';
import { tableLocaleSettings } from './Component/locale';
import { tablePagingSettings } from './Component/paging';
import { updateOrganisationId } from '../../../../../Store/Slices/organizationProfileModalSlice';
import { useAppDispatch, useAppSelector } from '../../../../../Store/hooks';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import OrganizaionInfoModal from '../../../../../Components/Modals/OrganizationInfoModal';

interface OrgTableProps extends ContractsTableProps {
  columnsSettings: unknown;
  tabName: OrgTableTabs;
  totalCount: number;
}

const ContractsTable = (props: OrgTableProps) => {
  const { data, rowSelection, loading, columnsSettings, tabName, totalCount } = props;
  const isVerified = useAppSelector((state) => state.user.confirmed);
  const dispatch = useAppDispatch();

  const [openModal, setOpenModal] = useState<boolean>(false);
  const showModal = (organisationId: number) => {
    dispatch(updateOrganisationId(organisationId));
    setOpenModal(true);
  };
  const hideModal = () => setOpenModal(false);

  const { filteredInfo, setFilteredInfo, paginationInfo, setPaginationInfo, sortInfo, setSortInfo, handleFilter } =
    getOrganizerContractsTableFilter();
  const columns = getOrganizerContractsTableColumns(columnsSettings, filteredInfo, setFilteredInfo, showModal);
  const [searchParams, setSearchParams] = useSearchParams();
  const sizePage = localStorage.getItem('sizePage');

  useEffect(() => {
    const handlers = document.querySelectorAll('.react-resizable .react-resizable-handle');
    handlers.forEach((handler) =>
      handler.addEventListener('click', () => {
        return false;
      }),
    );
  });

  useEffect(() => {
    getFilterData(paginationInfo, sortInfo, searchParams, setSearchParams);
    if (filteredInfo) {
      for (const [key, value] of Object.entries(filteredInfo)) {
        if (value !== null) {
          if (typeof value === 'object') {
            if (value[2] === 'gt') {
              dispatch(setOrganizerContractsFilters({ key: key, value: [value[0]] }));
            } else if (value[2] === 'lt') {
              dispatch(setOrganizerContractsFilters({ key: `${key}` + 'End', value: [value[0]] }));
            } else if (value[2] === 'eq') {
              const start: { key: string; value: FilterValue } = { key: key, value: [value[0]] };
              const end: { key: string; value: FilterValue } = { key: `${key}` + 'End', value: [value[0]] };
              dispatch(setOrganizerContractsFilters({ ...start }));
              dispatch(setOrganizerContractsFilters({ ...end }));
            } else {
              const start: { key: string; value: FilterValue } = { key: key, value: [value[0]] };
              const end: { key: string; value: FilterValue } = { key: `${key}` + 'End', value: [value[1]] };
              dispatch(setOrganizerContractsFilters({ ...start }));
              dispatch(setOrganizerContractsFilters({ ...end }));
            }
          } else {
            dispatch(setOrganizerContractsFilters({ key, value }));
          }
        }
      }
    } else dispatch(resetOrganizerContractsFilters());
  }, [paginationInfo, sortInfo, filteredInfo]);

  return (
    <>
      <Table
        bordered
        rowKey={'agreementId'}
        className="table__contracts"
        components={{
          header: {
            cell: ResizableTitle,
          },
        }}
        scroll={{ x: 1300 }}
        loading={loading}
        rowSelection={isVerified ? rowSelection : undefined}
        columns={columns}
        dataSource={data}
        locale={tableLocaleSettings(tabName)}
        pagination={tablePagingSettings(totalCount, sizePage)}
        onChange={handleFilter}
      />
      <OrganizaionInfoModal title="Данные о заказчике" open={openModal} handleCancel={hideModal} />
    </>
  );
};

export default ContractsTable;
