import { FilterValue } from 'antd/lib/table/interface';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

const initialState = {};

export const operatorContractsListSlice = createSlice({
  initialState: initialState,
  name: 'operatorContractsList',
  reducers: {
    setOperatorContractsFilters: (state, action: PayloadAction<{ key: string; value: FilterValue }>) => {
      const { key, value } = action.payload;
      state[key] = value[0];
    },
    resetOperatorContractsFilters: () => initialState,
  },
});

export const { setOperatorContractsFilters, resetOperatorContractsFilters } = operatorContractsListSlice.actions;

export default operatorContractsListSlice.reducer;
