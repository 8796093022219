import { API } from '../../../../Services/axios';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { IContractsTable } from '../../../../Models/Contract';
import { TableTabs } from '../../../../Components/Table/Components/TableTabs';
import { getContractsTabsItems } from './Component/tabs-items';
import { useAppSelector } from '../../../../Store/hooks';
import { useErrorModal } from '../../../../Components/Modals/ErrorModal';
import { useGetCustomerContractsQuery } from '../../../../Store/Slices/api';
import { useSearchParams } from 'react-router-dom';
import Toolbar from './Toolbar';

interface CustomerContractsProps extends IContractsTable {
  columnsSettings: unknown;
  setTab: Dispatch<SetStateAction<string>>;
  loading: boolean;
}

const Contracts = () => {
  const { showErrorModal } = useErrorModal();
  const [columnsSettings, setColumnsSettings] = useState<unknown>(null);

  const [searchParams, setSearchParams] = useSearchParams();
  const [tab, setTab] = useState(searchParams.get('filter')! !== '0' ? searchParams.get('filter')! : 'all');
  const page = searchParams.get('page') ? searchParams.get('page') : '1';
  const size = searchParams.get('size') ? searchParams.get('size') : localStorage.getItem('sizePage');
  const sort = searchParams.get('sort') ? searchParams.get('sort') : 'descend';
  const sortField = searchParams.get('sortField') ? searchParams.get('sortField') : 'agreementId';

  const params = { filter: {}, search: {} };
  const paramsSearch = useAppSelector((state) => state.customerContractsList);
  params.search = paramsSearch;

  searchParams.forEach((value, key) => {
    params.filter[key] = value;
  });

  const { data, isLoading, error } = useGetCustomerContractsQuery(params);
  if (error) {
    showErrorModal(error);
  }

  useEffect(() => {
    API.get(`/api/v1/table-settings/agreement`)
      .then((response) => setColumnsSettings(response.data))
      .catch((error) => showErrorModal({ error }));
  }, []);

  useEffect(() => {
    if (size) {
      localStorage.setItem('sizePage', size);
    }
    if (page && size && tab) {
      setSearchParams((params) => {
        params.set('page', page);
        return searchParams;
      });
      setSearchParams((params) => {
        params.set('size', size);
        return searchParams;
      });
      setSearchParams((params) => {
        params.set('filter', tab);
        return searchParams;
      });
    }
    if (sort && sortField) {
      setSearchParams((params) => {
        params.set('sort', sort);
        return searchParams;
      });
      setSearchParams((params) => {
        params.set('sortField', sortField);
        return searchParams;
      });
    }
  }, [tab, size, page, sort, sortField]);

  const contractsProps: CustomerContractsProps = {
    list: data?.list ? data?.list : [],
    totalCount: data?.totalCount ? data?.totalCount : 0,
    loading: isLoading,
    columnsSettings: columnsSettings,
    setTab: setTab,
  };

  const tabsItems = getContractsTabsItems(contractsProps);

  return (
    <div>
      <Toolbar columnsSettings={columnsSettings} setColumnsSettings={setColumnsSettings} />
      <TableTabs
        className="contracts__tabs-table"
        tab={tab}
        setTab={setTab}
        tabsItems={tabsItems}
        page={page}
        size={size}
        url="/customer/contracts"
      />
    </div>
  );
};

export default Contracts;
