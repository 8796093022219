import { Table } from 'antd';
import { UsersProps } from '../../../../../Models/Profile';
import { UsersTableTabs } from './Component/empty-message';
import { getAdminUsersTableColumns } from './Component/columns';
import { getUsersTableFilter } from './Component/filters';
import { resetAdminUsersFilters, setAdminUsersFilters } from '../../../../../Store/Slices/Admin/adminUsersSlice';

import { FilterValue } from 'antd/lib/table/interface';
import { getFilterData } from '../../../../../Utils/getFilterData';
import { tableLocaleSettings } from './Component/locale';
import { tablePagingSettings } from './Component/paging';
import { useAppDispatch } from '../../../../../Store/hooks';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

interface UsersTableProps extends UsersProps {
  tabName: UsersTableTabs;
  totalCount: number;
}

const UsersTable = (props: UsersTableProps) => {
  const { users, loading, tabName, totalCount } = props;
  const dispatch = useAppDispatch();

  const [searchParams, setSearchParams] = useSearchParams();
  const [tab, setTab] = useState(searchParams.get('filter') ?? 'all');
  const page = searchParams.get('page') ? searchParams.get('page') : '1';
  const size = searchParams.get('size') ? searchParams.get('size') : localStorage.getItem('sizePage');
  const sort = searchParams.get('sort') ? searchParams.get('sort') : 'descend';
  const sortField = searchParams.get('sortField') ? searchParams.get('sortField') : 'createdAt';
  const sizePage = localStorage.getItem('sizePage');

  const { filteredInfo, setFilteredInfo, paginationInfo, setPaginationInfo, sortInfo, setSortInfo, handleFilter } =
    getUsersTableFilter();
  const columns = getAdminUsersTableColumns(filteredInfo, setFilteredInfo);

  useEffect(() => {
    getFilterData(paginationInfo, sortInfo, searchParams, setSearchParams);
    if (filteredInfo) {
      for (const [key, value] of Object.entries(filteredInfo)) {
        if (value !== null) {
          if (typeof value === 'object') {
            if (value[2] === 'gt') {
              dispatch(setAdminUsersFilters({ key: key, value: [value[0]] }));
            } else if (value[2] === 'lt') {
              dispatch(setAdminUsersFilters({ key: `${key}` + 'End', value: [value[0]] }));
            } else if (value[2] === 'eq') {
              const start: { key: string; value: FilterValue } = { key: key, value: [value[0]] };
              const end: { key: string; value: FilterValue } = { key: `${key}` + 'End', value: [value[0]] };
              dispatch(setAdminUsersFilters({ ...start }));
              dispatch(setAdminUsersFilters({ ...end }));
            } else {
              const start: { key: string; value: FilterValue } = { key: key, value: [value[0]] };
              const end: { key: string; value: FilterValue } = { key: `${key}` + 'End', value: [value[1]] };
              dispatch(setAdminUsersFilters({ ...start }));
              dispatch(setAdminUsersFilters({ ...end }));
            }
          } else {
            dispatch(setAdminUsersFilters({ key, value }));
          }
        }
      }
    } else dispatch(resetAdminUsersFilters());
  }, [paginationInfo, sortInfo, filteredInfo]);
  return (
    <Table
      bordered
      rowKey={'id'}
      className="table__organizations"
      loading={loading}
      scroll={{ x: 1331 }}
      columns={columns}
      dataSource={users}
      locale={tableLocaleSettings(tabName)}
      pagination={tablePagingSettings(totalCount, sizePage)}
      onChange={handleFilter}
    />
  );
};

export default UsersTable;
