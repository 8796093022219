import { API } from '../../../../../Services/axios';
import { Button, Card, Col, Row, Typography } from 'antd';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { clickDownloadLink } from '../../../../../Utils/clickDownloadLink';
import { useAppSelector } from '../../../../../Store/hooks';
import { useEffect, useState } from 'react';
import { useErrorModal } from '../../../../../Components/Modals/ErrorModal';
import Loader from '../../../../../Components/Loader/Loader';
import dayjs from 'dayjs';

interface FinalAgreementCardProps {
  offerId: number;
}

const FinalAgreementCard = (props: FinalAgreementCardProps) => {
  const { offerId } = props;
  const [loading, setLoading] = useState(false);
  const contractId = useAppSelector((state) => state.order.agreementId);
  const [file, setFile] = useState();
  const { showErrorModal } = useErrorModal();

  const [fioCheck, setFioCheck] = useState(false);

  useEffect(() => {
    API.get(`/api/v1/profile/checkFIO/offer?offerId=${offerId}&type=CUSTOMER`)
      .then((response) => {
        setFioCheck(response.data);
      })
      .catch((error) => showErrorModal({ error }))
      .finally(() => setLoading(false));
  }, []);

  const handleClick = () => {
    setLoading(true);
    API.post(
      `/api/v1/fillAgreement?offerId=${offerId}`,
      {},
      {
        responseType: 'blob',
      },
    )
      .then((response) => {
        setFile(response.data);
        setLoading(false);
      })
      .catch((error) => {
        showErrorModal({ error });
        setLoading(false);
      });
  };

  const handleDownload = () => {
    if (file) {
      clickDownloadLink(file, `Договор${dayjs().format('YYYY_MM_DD')}.pdf`);
    }
  };

  return (
    <Card className="offer__full-width">
      <Row>
        <Col span={24} className="offer__title">
          <Typography.Title level={4}>Финальный договор</Typography.Title>
        </Col>
        {/*<Col span={24}>*/}
        {/*  <Space direction="vertical" size={12}>*/}
        {/*    <Typography.Text type="secondary" strong>*/}
        {/*      Файл подписанного договора*/}
        {/*    </Typography.Text>*/}
        {/*    <Space size={10}>*/}
        {/*      <FileOutlined className="offer__file-icon" />*/}
        {/*      <Typography.Link className="offer__text" href={file.link}>*/}
        {/*        {file.name}*/}
        {/*      </Typography.Link>*/}
        {/*    </Space>*/}
        {/*  </Space>*/}
        {/*</Col>*/}

        {loading ? (
          <Loader />
        ) : (
          <Col span={24} className="offer__actions">
            <Button
              size="large"
              style={{ whiteSpace: 'break-spaces', height: 'auto', textAlign: 'center', marginRight: '10px' }}
              type={'primary'}
              onClick={file ? handleDownload : handleClick}
            >
              {file ? 'Скачать договор' : 'Сформировать договор'}
            </Button>
            <Link to={`/customer/contracts/${contractId}`}>
              <Button
                size="large"
                style={{ whiteSpace: 'break-spaces', height: 'auto', textAlign: 'center' }}
                type={'primary'}
              >
                Перейти к отслеживанию договора
              </Button>
            </Link>
          </Col>
        )}
      </Row>
      {fioCheck && (
        <Row>
          <Col span={24}>
            <div className="order__warning-message">
              <Typography.Title level={5}>
                <ExclamationCircleFilled />
                <Typography.Link href="/profile" underline>
                  Заполните
                </Typography.Link>
                &nbsp;поле ФИО в родительном падеже.
              </Typography.Title>
            </div>
          </Col>
        </Row>
      )}
    </Card>
  );
};

export default FinalAgreementCard;
