import { Col, Row, Table, Typography } from 'antd';
import { getContractsTableColumns } from './Component/columns';
import { getContractsTableFilter } from './Component/filters';
import {
  resetOperatorContractsFilters,
  setOperatorContractsFilters,
} from '../../../../Store/Slices/Operator/operatorContractsSlice';

import { FilterValue } from 'antd/lib/table/interface';
import { getFilterData } from '../../../../Utils/getFilterData';
import { tableLocaleSettings } from './Component/locale';
import { tablePagingSettings } from './Component/paging';
import { useAppDispatch, useAppSelector } from '../../../../Store/hooks';
import { useEffect } from 'react';
import { useErrorModal } from '../../../../Components/Modals/ErrorModal';
import { useGetOperatorContractsQuery } from '../../../../Store/Slices/api';
import { useSearchParams } from 'react-router-dom';

const ContractsTable = () => {
  const { showErrorModal } = useErrorModal();
  const dispatch = useAppDispatch();

  const [searchParams, setSearchParams] = useSearchParams();
  const page = searchParams.get('page') ? searchParams.get('page') : '1';
  const size = searchParams.get('size') ? searchParams.get('size') : localStorage.getItem('sizePage');
  const sort = searchParams.get('sort') ? searchParams.get('sort') : 'descend';
  const sortField = searchParams.get('sortField') ? searchParams.get('sortField') : 'createdAt';

  const params = { filter: {}, search: {} };
  const paramsSearch = useAppSelector((state) => state.operatorContractsList);
  params.search = paramsSearch;

  searchParams.forEach((value, key) => {
    params.filter[key] = value;
  });

  const { data, isLoading, error } = useGetOperatorContractsQuery(params);
  if (error) {
    showErrorModal(error);
  }

  useEffect(() => {
    if (size) {
      localStorage.setItem('sizePage', size);
    }
    if (page && size) {
      setSearchParams((params) => {
        params.set('page', page);
        return searchParams;
      });
      setSearchParams((params) => {
        params.set('size', size);
        return searchParams;
      });
    }
    if (sort && sortField) {
      setSearchParams((params) => {
        params.set('sort', sort);
        return searchParams;
      });
      setSearchParams((params) => {
        params.set('sortField', sortField);
        return searchParams;
      });
    }
  }, [page, size, sort, sortField]);

  const sizePage = localStorage.getItem('sizePage');

  const { filteredInfo, setFilteredInfo, paginationInfo, setPaginationInfo, sortInfo, setSortInfo, handleFilter } =
    getContractsTableFilter();
  const columns = getContractsTableColumns(filteredInfo, setFilteredInfo);

  useEffect(() => {
    getFilterData(paginationInfo, sortInfo, searchParams, setSearchParams);
    if (filteredInfo) {
      for (const [key, value] of Object.entries(filteredInfo)) {
        if (value !== null) {
          if (typeof value === 'object') {
            if (value[2] === 'gt') {
              dispatch(setOperatorContractsFilters({ key: key, value: [value[0]] }));
            } else if (value[2] === 'lt') {
              dispatch(setOperatorContractsFilters({ key: `${key}` + 'End', value: [value[0]] }));
            } else if (value[2] === 'eq') {
              const start: { key: string; value: FilterValue } = { key: key, value: [value[0]] };
              const end: { key: string; value: FilterValue } = { key: `${key}` + 'End', value: [value[0]] };
              dispatch(setOperatorContractsFilters({ ...start }));
              dispatch(setOperatorContractsFilters({ ...end }));
            } else {
              const start: { key: string; value: FilterValue } = { key: key, value: [value[0]] };
              const end: { key: string; value: FilterValue } = { key: `${key}` + 'End', value: [value[1]] };
              dispatch(setOperatorContractsFilters({ ...start }));
              dispatch(setOperatorContractsFilters({ ...end }));
            }
          } else {
            dispatch(setOperatorContractsFilters({ key, value }));
          }
        }
      }
    } else dispatch(resetOperatorContractsFilters());
  }, [paginationInfo, sortInfo, filteredInfo]);
  return (
    <div>
      <Row className="toolbar__title" justify="space-between">
        <Col>
          <Typography.Title level={2}>Договоры</Typography.Title>
        </Col>
      </Row>
      <div>
        <Table
          bordered
          rowKey={'agreementId'}
          className="table__organizations"
          scroll={{ x: 1331 }}
          loading={isLoading}
          columns={columns}
          dataSource={data?.list}
          locale={tableLocaleSettings}
          pagination={tablePagingSettings(data?.totalCount ?? 0, sizePage)}
          onChange={handleFilter}
        />
      </div>
    </div>
  );
};

export default ContractsTable;
