import { FilterValue } from 'antd/lib/table/interface';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

const initialState = {};

export const customerContractsListSlice = createSlice({
  initialState: initialState,
  name: 'customerContractsList',
  reducers: {
    setCustomerContractsFilters: (state, action: PayloadAction<{ key: string; value: FilterValue }>) => {
      const { key, value } = action.payload;
      state[key] = value[0];
    },
    resetCustomerContractsFilters: () => initialState,
  },
});

export const { setCustomerContractsFilters, resetCustomerContractsFilters } = customerContractsListSlice.actions;

export default customerContractsListSlice.reducer;
