import { Button, InputRef, Typography } from 'antd';
import { ColumnType, FilterConfirmProps, FilterValue } from 'antd/lib/table/interface';
import { ColumnsType } from 'antd/lib/table';
import { DateFilter } from '../../../../../Components/Filters/DateFilter';
import { FilterOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { NumberFilter } from '../../../../../Components/Filters/NumberFilter';
import { Order } from '../../../../../Models/Order';
import { StringFilter } from '../../../../../Components/Filters/StringFilter';
import { defaultDateFormat } from '../../../../../Constants/DateFormats';
import { formatPrice } from '../../../../../Utils/formatPrice';
import { getOrderPropertyType } from '../../../../../Utils/getOrderPropertyType';
import {
  resetOperatorOrdersFilters,
  setOperatorOrdersFilters,
} from '../../../../../Store/Slices/Operator/operatorOrdersSlice';
import { useAppDispatch } from '../../../../../Store/hooks';
import { useRef, useState } from 'react';
import dayjs from 'dayjs';

export const getOrdersTableColumns = (
  filteredInfo: Record<string, FilterValue | null>,
  setFilteredInfo: React.Dispatch<React.SetStateAction<Record<string, FilterValue | null>>>,
) => {
  type DataIndex = keyof Order;
  const dispatch = useAppDispatch();
  const columns: ColumnsType<Order> = [
    {
      key: 'userFriendlyId',
      title: '№',
      dataIndex: ['userFriendlyId', 'id'],
      sorter: true,
      render: (_, record) => (
        <Link to={`/operator/orders/${record.id}`}>
          <Button type="default">{record.userFriendlyId}</Button>
        </Link>
      ),
      width: 120,
    },
    {
      key: 'organisationName',
      title: 'Заказчик',
      dataIndex: 'organisationName',
      ellipsis: true,
      sorter: true,
      render: (_, record) => (
        <Typography.Link href={`/operator/organizations/${record.organisationId}`} underline>
          {record.organisationName}
        </Typography.Link>
      ),
      width: 157,
    },
    {
      key: 'cargoTypeNameWithParentName',
      title: 'Категория техники',
      dataIndex: 'cargoTypeNameWithParentName',
      ellipsis: true,
      sorter: true,
      width: 322,
    },
    {
      key: 'cityFrom',
      title: 'Город отправки',
      dataIndex: 'cityFrom',
      ellipsis: true,
      sorter: true,
      render: (_, record) =>
        `${
          record.regionFrom && record.regionFrom !== record.cityFrom
            ? record.cityFrom + `, ` + record.regionFrom
            : record.cityFrom
        }`,
      width: 225,
    },
    {
      key: 'cityTo',
      title: 'Город доставки',
      dataIndex: 'cityTo',
      ellipsis: true,
      sorter: true,
      render: (_, record) =>
        `${
          record.regionTo && record.regionTo !== record.cityTo ? record.cityTo + `, ` + record.regionTo : record.cityTo
        }`,
      width: 225,
    },
    {
      key: 'dateArrival',
      title: 'Дата получения',
      dataIndex: 'dateArrival',
      ellipsis: true,
      sorter: true,
      render: (text) => dayjs(text).format(defaultDateFormat),
      width: 145,
    },
    {
      key: 'price',
      title: 'Цена',
      dataIndex: 'price',
      align: 'right',
      ellipsis: true,
      sorter: true,
      render: (_, record) => `${formatPrice(+record.price)} ₽`,
      width: 118,
    },
  ];
  const [searchText, setSearchText] = useState<string | number>('');
  const [searchedColumn, setSearchedColumn] = useState('');

  const handleSearch = (
    selectedKeys: string[],
    confirm: (param?: FilterConfirmProps) => void,
    dataIndex: DataIndex,
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
    dispatch(setOperatorOrdersFilters({ key: dataIndex, value: selectedKeys }));
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setFilteredInfo({});
    setSearchText('');
    setSearchedColumn('');
    dispatch(resetOperatorOrdersFilters());
  };

  const searchInput = useRef<InputRef>(null);
  const getColumnSearchProps = (dataIndex: keyof Order): ColumnType<Order> => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
      const [showSegment, setShowSegment] = useState(false);
      const [selectedFilter, setSelectedFilter] = useState('eq');
      const handleFilterChange = (operator: string) => {
        setSelectedKeys([]);
        setShowSegment(operator === 'seg');
        setSelectedFilter(operator);
      };
      return `${getOrderPropertyType(dataIndex)}` === 'string' || dataIndex === 'userFriendlyId' ? (
        <StringFilter
          searchInput={searchInput}
          selectedKeys={selectedKeys as string[]}
          setSelectedKeys={setSelectedKeys}
          handleSearch={handleSearch}
          handleReset={handleReset}
          confirm={confirm}
          dataIndex={dataIndex}
          clearFilters={clearFilters}
          setSearchText={setSearchText}
          setSearchedColumn={setSearchedColumn}
        />
      ) : `${getOrderPropertyType(dataIndex)}` === 'number' ? (
        <NumberFilter
          searchInput={searchInput}
          selectedKeys={selectedKeys as string[]}
          setSelectedKeys={setSelectedKeys}
          handleSearch={handleSearch}
          handleReset={handleReset}
          handleFilterChange={handleFilterChange}
          confirm={confirm}
          dataIndex={dataIndex}
          clearFilters={clearFilters}
          setSearchText={setSearchText}
          setSearchedColumn={setSearchedColumn}
          showSegment={showSegment}
          selectedFilter={selectedFilter}
        />
      ) : `${getOrderPropertyType(dataIndex)}` === 'object' ? (
        <DateFilter
          searchInput={searchInput}
          selectedKeys={selectedKeys as string[]}
          setSelectedKeys={setSelectedKeys}
          handleSearch={handleSearch}
          handleReset={handleReset}
          handleFilterChange={handleFilterChange}
          confirm={confirm}
          dataIndex={dataIndex}
          clearFilters={clearFilters}
          setSearchText={setSearchText}
          setSearchedColumn={setSearchedColumn}
          showSegment={showSegment}
          selectedFilter={selectedFilter}
        />
      ) : (
        <></>
      );
    },
    filteredValue: filteredInfo[dataIndex] || null,
    filterIcon: (filtered: boolean) => <FilterOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
  });

  const filterableColumns: ColumnsType<Order> = columns.map((col, index) => {
    const dataIndex = col.key! as keyof Order;
    return {
      ...col,
      ...getColumnSearchProps(dataIndex),
    };
  });

  return filterableColumns;
};
