/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, Select, Space } from 'antd';
import { FilterConfirmProps } from 'antd/lib/table/interface';
import { FilterOutlined } from '@ant-design/icons';
import { SelectOption } from '../../Models/CargoCategories';

interface SelectFilterProps {
  selectedKeys: string[];
  setSelectedKeys: (selectedKeys: React.Key[]) => void;
  handleSearch: (selectedKeys: string[], confirm: (param?: FilterConfirmProps) => void, dataIndex: any) => void;
  handleReset: (clearFilters: () => void) => void;
  confirm: (param?: FilterConfirmProps | undefined) => void;
  dataIndex: any;
  clearFilters: (() => void) | undefined;
  setSearchText: (value: React.SetStateAction<string | number>) => void;
  setSearchedColumn: (value: React.SetStateAction<string>) => void;
  selectOptions: SelectOption[];
}

export const SelectFilter = (props: SelectFilterProps) => {
  const {
    selectedKeys,
    setSelectedKeys,
    handleSearch,
    handleReset,
    confirm,
    dataIndex,
    clearFilters,
    setSearchText,
    setSearchedColumn,
    selectOptions,
  } = props;
  return (
    <div style={{ padding: 8 }}>
      <Select
        style={{ marginBottom: 8, display: 'block' }}
        options={selectOptions}
        value={selectedKeys[0]}
        onChange={(e) => {
          const newSelectedKeys = selectedKeys;
          newSelectedKeys[0] = e ? e : '';
          setSelectedKeys(newSelectedKeys);
        }}
      />
      <Space>
        <Button onClick={() => clearFilters && handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Сброс
        </Button>
        <Button
          size="small"
          type="primary"
          icon={<FilterOutlined />}
          onClick={() => {
            confirm({ closeDropdown: false });
            setSearchText(selectedKeys.toString());
            setSearchedColumn(dataIndex);
          }}
        >
          Фильтровать
        </Button>
      </Space>
    </div>
  );
};
