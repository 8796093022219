/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, InputRef, Select, Space } from 'antd';
import { FilterConfirmProps } from 'antd/lib/table/interface';
import { FilterOutlined } from '@ant-design/icons';
import { defaultDateFormat, serverDateFormat } from '../../Constants/DateFormats';
import DatePicker from '../DatePicker/DatePicker';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import dayjs from 'dayjs';
import locale from 'antd/es/date-picker/locale/ru_RU';

dayjs().locale('ru');
dayjs.extend(customParseFormat);

interface DateFilterProps {
  searchInput: React.RefObject<InputRef>;
  selectedKeys: string[];
  setSelectedKeys: (selectedKeys: React.Key[]) => void;
  handleSearch: (selectedKeys: string[], confirm: (param?: FilterConfirmProps) => void, dataIndex: any) => void;
  handleReset: (clearFilters: () => void) => void;
  handleFilterChange: (operator: string) => void;
  confirm: (param?: FilterConfirmProps | undefined) => void;
  dataIndex: any;
  clearFilters: (() => void) | undefined;
  setSearchText: (value: string | number) => void;
  setSearchedColumn: (value: React.SetStateAction<string>) => void;
  showSegment: boolean;
  selectedFilter: string;
}

interface RangePickerProps {
  onChange?: (date) => void;
}

const DateFilter = (props: DateFilterProps) => {
  const {
    searchInput,
    selectedKeys,
    setSelectedKeys,
    handleSearch,
    handleReset,
    handleFilterChange,
    confirm,
    dataIndex,
    clearFilters,
    setSearchText,
    setSearchedColumn,
    showSegment,
    selectedFilter,
  } = props;
  const { RangePicker } = DatePicker;
  return (
    <div style={{ padding: 8 }}>
      <Select
        defaultValue="eq"
        style={{ marginBottom: 8, display: 'block' }}
        onChange={handleFilterChange}
        options={[
          { value: 'eq', label: 'Точная дата' },
          { value: 'seg', label: 'Диапазон' },
        ]}
      />
      {!showSegment && (
        <DatePicker
          format={defaultDateFormat}
          size="small"
          placeholder="Значение"
          getPopupContainer={(trigger) => trigger.parentElement!}
          locale={locale}
          onChange={(date) => {
            const newSelectedKeys = selectedKeys;
            newSelectedKeys[0] = date ? date.format(serverDateFormat).toString() : '';
            newSelectedKeys[2] = selectedFilter;
            setSelectedKeys(newSelectedKeys);
          }}
          style={{ marginBottom: 8, display: 'block' }}
        />
      )}
      {showSegment && (
        <div style={{ marginBottom: 8, display: 'block' }}>
          <RangePicker
            format={defaultDateFormat}
            size="small"
            placeholder={['От', 'До']}
            getPopupContainer={(trigger) => trigger.parentElement!}
            locale={locale}
            onChange={(date) => {
              const newSelectedKeys = selectedKeys;
              newSelectedKeys[0] = date ? date[0]!.format(serverDateFormat).toString() : '';
              newSelectedKeys[1] = date ? date[1]!.format(serverDateFormat).toString() : '';
              newSelectedKeys[2] = selectedFilter;
              setSelectedKeys(newSelectedKeys);
            }}
          />
        </div>
      )}
      <Space>
        <Button onClick={() => clearFilters && handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Сброс
        </Button>
        <Button
          size="small"
          type="primary"
          icon={<FilterOutlined />}
          onClick={() => {
            confirm({ closeDropdown: false });
            setSearchText(selectedKeys[0]);
            setSearchedColumn(dataIndex);
          }}
        >
          Фильтровать
        </Button>
      </Space>
    </div>
  );
};

const filterDate = (value, filter) => {
  const startDateValues = filter[0].split('.');
  const endDateValues = filter[1]?.split('.') ?? '';
  const startDate = new Date(startDateValues[2], +startDateValues[1] - 1, startDateValues[0]);
  const endDate = new Date(endDateValues[2], +endDateValues[1] - 1, endDateValues[0]);
  switch (filter[2]) {
    case 'eq':
      return dayjs(value).format(defaultDateFormat) === filter[0];
    case 'seg':
      return (
        new Date(new Date(value).setHours(0, 0, 0, 0)) >= startDate &&
        new Date(new Date(value).setHours(0, 0, 0, 0)) <= endDate
      );
    default:
      return false;
  }
};

export { DateFilter, filterDate };
