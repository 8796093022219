import { FilterValue } from 'antd/lib/table/interface';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

const initialState = {};

export const customerOrdersListSlice = createSlice({
  initialState: initialState,
  name: 'customerOrdersList',
  reducers: {
    setCustomerOrdersFilters: (state, action: PayloadAction<{ key: string; value: FilterValue }>) => {
      const { key, value } = action.payload;
      state[key] = value[0];
    },
    resetCustomerOrdersFilters: () => initialState,
  },
});

export const { setCustomerOrdersFilters, resetCustomerOrdersFilters } = customerOrdersListSlice.actions;

export default customerOrdersListSlice.reducer;
