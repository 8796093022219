import { API } from '../../../../Services/axios';
import { Card, Col, Row, Space, Typography } from 'antd';
import { LinkOutlined } from '@ant-design/icons';
import { OfferType } from '../../../../Models/Offer';
import { Order } from '../../../../Models/Order';
import { defaultDateFormat } from '../../../../Constants/DateFormats';
import { formatPrice } from '../../../../Utils/formatPrice';
import { getMaskedPhoneNumberInOrder } from '../../../../Utils/getPhoneMasked';
import { updateOrganisationId } from '../../../../Store/Slices/organizationProfileModalSlice';
import { useAppDispatch, useAppSelector } from '../../../../Store/hooks';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import Adresses from '../../../../Components/Adresses/Adresses';
import BlockedRibbon from '../../../../Components/Blocked/BlockedRibbon';
import CancelOrderModal from '../../../../Components/Modals/CancelOrderByOrgModal';
import CargoDimensions from '../../../../Components/CargoDimensions/CargoDimensions';
import ExecutorLink from './Component/executor-link';
import Offer from './Offer';
import OrderAttachments from '../../../../Components/Order/OrderAttachments';
import OrderHeader from './Component/order-header';
import OrganizaionInfoModal from '../../../../Components/Modals/OrganizationInfoModal';
import UnverifiedOrgAlert from './Component/unverified-alert';
import dayjs from 'dayjs';

const OrderDetails = () => {
  const { id } = useParams();
  const isVerified = useAppSelector((state) => state.user.confirmed);
  const dispatch = useAppDispatch();

  const [openOrganisationModal, setOpenOrganisationModal] = useState<boolean>(false);
  const showOrganisationModal = (organisationId: number) => {
    dispatch(updateOrganisationId(organisationId));
    setOpenOrganisationModal(true);
  };
  const hideOrganisationModal = () => setOpenOrganisationModal(false);

  const [data, setData] = useState<Order>();
  const [offerData, setOfferData] = useState<OfferType>();
  const [loading, setLoading] = useState<boolean>(false);
  const [reload, setReload] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);

  const showCancelOrderModal = () => setOpenModal(true);
  const hideCancelOrderModal = () => setOpenModal(false);

  useEffect(() => {
    API.get(`/api/v1/order/order-for-delivery/${id}`).then((response) => {
      setData(response.data);
      setReload(false);
    });
  }, [reload]);

  useEffect(() => {
    setLoading(true);
    API.get(`/api/v1/offer/get-if-present/${id}`).then((response) => {
      setOfferData(response.data);
      setLoading(false);
    });
  }, []);

  const customer = data && (
    <Space direction="vertical">
      <Typography.Text className="order__info" type="secondary" strong>
        Заказчик
      </Typography.Text>
      <Typography.Link onClick={() => showOrganisationModal(data.userId)} className="order__info-large" underline>
        {data.organisationName}
      </Typography.Link>
    </Space>
  );

  return (
    <>
      {!isVerified && (
        <div className="profile__unverified">
          <UnverifiedOrgAlert />
        </div>
      )}
      {
        (data && (
          <Row justify="center" className="order__container" gutter={[24, 24]}>
            <Col lg={{ span: 24 }} span={24}>
              <OrderHeader
                title={`${data.userFriendlyId} ${data.machineName}`}
                orderId={data.id}
                orderNumber={data.userFriendlyId.toString()}
                offerStatus={offerData?.status}
                orderStatus={data.status}
                createdAt={data.createdAt}
                showCancelOrderModal={showCancelOrderModal}
              />
            </Col>
            <Col lg={{ span: 10, order: 0 }} span={24} order={1}>
              <Card className="order__card" title={<Typography.Title level={4}>Информация о заказе</Typography.Title>}>
                <Row gutter={[0, 24]}>
                  <Col span={24}>
                    <Space direction="vertical">
                      <Typography.Text className="order__info" type="secondary" strong>
                        Предварительная оценочная стоимость
                      </Typography.Text>
                      <Typography className="order__info-large">{`${formatPrice(data.price)} ₽`}</Typography>
                    </Space>
                  </Col>

                  <Col span={24}>
                    <Space direction="vertical">
                      <Typography.Text className="order__info" type="secondary" strong>
                        Желаемая дата получения заказа
                      </Typography.Text>
                      <Typography className="order__info-large">
                        {dayjs(data.dateArrival).format(defaultDateFormat)}
                      </Typography>
                    </Space>
                  </Col>

                  <Adresses
                    from={data.addressFrom}
                    to={data.addressTo}
                    pointFrom={data.pointFrom.split(',').map((point) => {
                      return +point;
                    })}
                    pointTo={data.pointTo.split(',').map((point) => {
                      return +point;
                    })}
                  />

                  <Col span={24}>
                    <Space direction="vertical">
                      <Typography.Text className="order__info" type="secondary" strong>
                        Расчетное расстояние
                      </Typography.Text>
                      <Typography className="order__info-large">{data.distance} км</Typography>
                    </Space>
                  </Col>

                  <CargoDimensions height={data.height} weight={data.weight} width={data.width} length={data.length} />

                  <Col span={24}>
                    <Space direction="vertical">
                      <Typography.Text className="order__info" type="secondary" strong>
                        Тип техники для транспортировки
                      </Typography.Text>
                      <Typography.Text className="order__info-large">
                        {data.transport === 'TRAILER' ? 'Трал' : 'Фура'}
                      </Typography.Text>
                    </Space>
                  </Col>

                  {isVerified && (
                    <>
                      <Col span={24}>
                        {data.organisationLocked ? <BlockedRibbon isOffer={false}>{customer}</BlockedRibbon> : customer}
                      </Col>

                      <Col span={24}>
                        <Space direction="vertical">
                          <Typography.Text className="order__info" type="secondary" strong>
                            Телефон для уточнения деталей
                          </Typography.Text>
                          <Typography.Text className="order__info-large">
                            {getMaskedPhoneNumberInOrder(data.phone)}
                          </Typography.Text>
                        </Space>
                      </Col>
                    </>
                  )}

                  <Col span={24}>
                    <Space direction="vertical">
                      <Typography.Text className="order__info" type="secondary" strong>
                        Категория техники
                      </Typography.Text>
                      <Typography className="order__info-large">{data.cargoTypeNameWithParentName}</Typography>
                    </Space>
                  </Col>

                  <Col span={24}>
                    <Space direction="vertical">
                      <Typography.Text className="contract__info" type="secondary" strong>
                        Модель техники
                      </Typography.Text>
                      {data.specificationLink ? (
                        <Space size={12} align="center">
                          <Typography.Link href={data.specificationLink} className="contract__info-large" underline>
                            {data.machineName}
                          </Typography.Link>
                          <Typography.Link href={data.specificationLink}>
                            <LinkOutlined />
                          </Typography.Link>
                        </Space>
                      ) : (
                        <Typography.Text className="contract__info-large">{data.machineName}</Typography.Text>
                      )}
                    </Space>
                  </Col>

                  <Col span={24}>
                    <Space direction="vertical">
                      <Typography.Text className="order__info" type="secondary" strong>
                        Стоимость перевозимой техники
                      </Typography.Text>
                      <Typography className="order__info-large">{`${formatPrice(data.machinePrice)} ₽`}</Typography>
                    </Space>
                  </Col>

                  <OrderAttachments files={data.files} photos={data.photos} comment={data.comment} />

                  <ExecutorLink
                    link={data.executorOrderLink}
                    isVerified={isVerified}
                    setReload={setReload}
                    orderId={data.id}
                  />
                </Row>
              </Card>
            </Col>

            <Offer
              loading={loading}
              orderStatus={data.status}
              contractId={data.acceptedAgreementId}
              externalCustomerId={data.externalCustomerId}
              offerData={offerData}
            />
            <CancelOrderModal open={openModal} handleCancel={hideCancelOrderModal} />
            <OrganizaionInfoModal
              title="Данные о заказчике"
              open={openOrganisationModal}
              handleCancel={hideOrganisationModal}
            />
          </Row>
        ))!
      }
    </>
  );
};

export default OrderDetails;
