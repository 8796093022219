import { Alert, Card, Col, Row, Space, Typography } from 'antd';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { OrgTypes } from '../../../Constants/OrgTypes';
import { Profile } from '../../../Models/Profile';
import { getMaskedPhoneNumberInProfile } from '../../../Utils/getPhoneMasked';
import { getUserAvatar } from '../../../Store/Slices/userSlice';
import { useAppDispatch } from '../../../Store/hooks';
import EditOrgProfileModal from '../../../Components/Modals/EditOrgProfileModal';
import ProfileAvatar from '../../../Components/Profile/ProfileAvatar';
import ProfileItem from '../../../Components/Profile/ProfileItem';

interface CustomerProfileCardProps {
  profileData: Profile;
  setReload: Dispatch<SetStateAction<boolean>>;
}

const CustomerProfileCard = (props: CustomerProfileCardProps) => {
  const { profileData, setReload } = props;
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getUserAvatar(profileData.avatar?.link));
  }, [profileData]);

  const [openModal, setOpenModal] = useState<boolean>(false);
  const hideModal = () => setOpenModal(false);
  const showModal = () => setOpenModal(true);

  const isUserLegal = profileData.organisation.type === OrgTypes.LEGAL ? true : false;

  const managementName = profileData.organisation.managementPatronymic
    ? `${profileData.organisation.managementSurname} ${profileData.organisation.managementName} ${profileData.organisation.managementPatronymic}`
    : `${profileData.organisation.managementSurname} ${profileData.organisation.managementName}`;

  const cardTitle = (
    <Row justify="space-between" align="middle">
      <Col>
        <Typography.Title level={3}>Данные об организации</Typography.Title>
      </Col>
      <Col>
        <Typography.Link onClick={showModal} underline>
          Редактировать
        </Typography.Link>
      </Col>
    </Row>
  );

  return (
    <Space direction="vertical" size={16}>
      <Alert
        message="Рекомендуем заполнить данные о Вашей организации и её реквизиты. Это ускорит процесс составления проектов договоров к Вашим заказам со стороны организаторов перевозок и, как следствие, сэкономит Ваше время."
        type="warning"
      />
      <Card title={cardTitle}>
        <Row gutter={[0, 40]} style={{ width: '100%' }}>
          <Row gutter={[24, 32]} justify="space-between">
            <Col span={24}>
              <Typography.Title level={4}>Основная информация</Typography.Title>
            </Col>
            <Col lg={{ span: 19 }} xl={{ span: 19 }} xxl={{ span: 19 }} span={24}>
              <Space direction="vertical" size={16} style={{ width: '100%' }}>
                <ProfileItem
                  title="Организационно-правовая форма"
                  value={isUserLegal ? 'Юридическое лицо' : 'Индивидуальный предприниматель'}
                />
                <ProfileItem title="ИНН" value={profileData.organisation.inn} />
                {isUserLegal && <ProfileItem title="КПП" value={profileData.organisation.kpp} />}
                <ProfileItem
                  title="Краткое наименование организации"
                  value={profileData.organisation.shortName ? profileData.organisation.shortName : '—'}
                />
                <ProfileItem title="Полное наименование организации" value={profileData.organisation.name ?? '—'} />
                {/* <ProfileItem
                  title="Дата внесения в реестр"
                  value={dayjs(profileData.organisation.ogrnDate).format(defaultDateFormat) ?? '—'}
                /> */}
                {isUserLegal ? (
                  <>
                    <ProfileItem title="Юридический адрес" value={profileData.organisation.businessAddress ?? '—'} />
                    <ProfileItem title="Почтовый адрес" value={profileData.organisation.postalAddress ?? '—'} />
                  </>
                ) : (
                  <ProfileItem title="Адрес регистрации" value={profileData.organisation.businessAddress ?? '—'} />
                )}
                <ProfileItem
                  title={isUserLegal ? 'ОГРН' : 'ОГРНИП'}
                  value={isUserLegal ? profileData.organisation.ogrn ?? '—' : profileData.organisation.ogrnip ?? '—'}
                />
                <ProfileItem title="ОКПО" value={profileData.organisation.okpo ?? '—'} />
                <ProfileItem title="ОКВЭД" value={profileData.organisation.okved ?? '—'} />
              </Space>
            </Col>
            <Col span={'120px'}>
              <ProfileAvatar avatarLink={profileData.avatar?.link} setReload={setReload} />
            </Col>
          </Row>
          <Row gutter={[24, 32]} style={{ width: '100%' }}>
            <Col span={24}>
              <Typography.Title level={4}>Платежная информация</Typography.Title>
            </Col>
            <Col lg={{ span: 19 }} xl={{ span: 10 }} xxl={{ span: 19 }} span={24}>
              <Space direction="vertical" size={16} style={{ width: '100%' }}>
                <ProfileItem title="Банк" value={profileData.organisation.bankName ?? '—'} />
                <ProfileItem title="Расчетный счет" value={profileData.organisation.paymentAccount ?? '—'} />
                <ProfileItem
                  title="Корреспондентский счет"
                  value={profileData.organisation.correspondentAccount ?? '—'}
                />
                <ProfileItem title="БИК" value={profileData.organisation.bic ?? '—'} />
              </Space>
            </Col>
          </Row>
          <Row gutter={[24, 32]} style={{ width: '100%' }}>
            <Col span={24}>
              <Typography.Title level={4}>Данные о руководителе</Typography.Title>
            </Col>
            <Col lg={{ span: 19 }} xl={{ span: 19 }} xxl={{ span: 19 }} span={24}>
              <Space direction="vertical" size={16} style={{ width: '100%' }}>
                <ProfileItem title="ФИО" value={profileData.organisation.managementName ? managementName : '—'} />
                {profileData.organisation.fioPDF && (
                  <ProfileItem title={'Склонённое ФИО'} value={profileData.organisation.fioPDF} />
                )}
                {isUserLegal && (
                  <ProfileItem title="Должность" value={profileData.organisation.managementPost ?? '—'} />
                )}
              </Space>
            </Col>
          </Row>
          <Row gutter={[24, 32]} style={{ width: '100%' }}>
            <Col span={24}>
              <Typography.Title level={4}>Дополнительные сведения</Typography.Title>
            </Col>
            <Col span={19}>
              <Space direction="vertical" size={16} style={{ width: '100%' }}>
                <ProfileItem title="Система налогообложения" value={profileData.organisation.taxationSystem ?? '—'} />
                <ProfileItem title="Электронная почта" value={profileData.organisation.email ?? '—'} />
                <ProfileItem
                  title="Телефон организации"
                  value={
                    profileData.organisation.phone ? getMaskedPhoneNumberInProfile(profileData.organisation.phone) : '—'
                  }
                />
              </Space>
            </Col>
          </Row>
        </Row>
      </Card>
      <EditOrgProfileModal profileData={profileData} open={openModal} setReload={setReload} handleCancel={hideModal} />
    </Space>
  );
};

export default CustomerProfileCard;
